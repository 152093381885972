import React from "react";
import styles from "./RouteForm.module.scss";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { FormControl, Checkbox, FormControlLabel, Link, FormHelperText } from '@material-ui/core';
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import PlaceInput from "../Autocomplete/Place/Place.jsx";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import plLocale from 'date-fns/locale/pl';
import DateFnsIO from '@date-io/date-fns';
import { format as formatDate } from 'date-fns';
import CircularProgress from '@material-ui/core/CircularProgress';
import ServiceHelper from "../../utils/Services";
import Control from '../Common/Control/Control.jsx';
import RouteInfoDialog from './RouteInfoDialog/RouteInfoDialog';
import Tickets from './Tickets/Tickets';
import SubmitRequestDialog from './SubmitRequestDialog/SubmitRequestDialog.jsx';
import LuggageInfoDialog from '../LuggageInfoDialog/LuggageInfoDialog.jsx';
import { cloneDeep as Clone, isEqual } from 'lodash';
import Payment from "./Payment/Payment";
class RouteForm extends React.PureComponent {
    constructor(props) {
        super(props);
        const routeInfo = props.routeFormData && props.routeFormData.routeInfo ? props.routeFormData.routeInfo : null;
        const fromAddressDetails = routeInfo && routeInfo.data ? routeInfo.data.from : null;
        const toAddressDetails = routeInfo && routeInfo.data ? routeInfo.data.to : null;
        let loadFromMemoryExcept = {};
        if (props.routeFormData && props.routeFormData.addressFrom && props.routeFormData.addressTo && fromAddressDetails && toAddressDetails) {
            loadFromMemoryExcept = {
                fromStreet: true,
                fromNumber: true,
                fromCity: true,
                fromPostalCode: true,
                fromCountry: true,
                toStreet: true,
                toNumber: true,
                toCity: true,
                toPostalCode: true,
                toCountry: true,
                date: true,
                ticketType: true
            };
        }
        this.state = {
            snackOpen: false,
            snackText: "",
            busy: false,
            submitResponse: null,
            fieldsValues: {
                addressFrom: props.routeFormData ? props.routeFormData.addressFrom : null,
                addressTo: props.routeFormData ? props.routeFormData.addressTo : null,
                fromStreet: fromAddressDetails ? fromAddressDetails.street : "",
                fromNumber: fromAddressDetails ? fromAddressDetails.number : "",
                fromCity: fromAddressDetails ? fromAddressDetails.city : "",
                fromPostalCode: fromAddressDetails ? fromAddressDetails.postal_code : "",
                fromCountry: fromAddressDetails ? fromAddressDetails.country : "",
                toStreet: toAddressDetails ? toAddressDetails.street : "",
                toNumber: toAddressDetails ? toAddressDetails.number : "",
                toCity: toAddressDetails ? toAddressDetails.city : "",
                toPostalCode: toAddressDetails ? toAddressDetails.postal_code : "",
                toCountry: toAddressDetails ? toAddressDetails.country : "",
                date: props.routeFormData ? props.routeFormData.date : null,
                'adult/0/firstName': "",
                'adult/0/lastName': "",
                'adult/0/email': "",
                'adult/0/phone': "",
                additionalLuggage: false,
                additionalLuggageNote: "",
                note: "",
                ticketType: props.routeFormData ? props.routeFormData.ticketType : null,
                noOfAdults: 1,
                noOfKids: 0,
                paymentType: 'CONTROLLED',
                invoice: false,
                payAddrCountry: "",
                payAddrPostalCode: "",
                payAddrCity: "",
                payAddrStreet: "",
                payAddrNumber: "",
                payAddrVatId: "",
                payAddrCompanyName: "",
                regulationsAccepted: false
            },
            stepFields: {
                basic_form: {
                    addressFrom: true,
                    addressTo: true,
                    date: true
                },
                address: {
                    date: true,
                    fromStreet: true,
                    fromNumber: true,
                    fromCity: true,
                    fromPostalCode: true,
                    fromCountry: true,
                    toStreet: true,
                    toNumber: true,
                    toCity: true,
                    toPostalCode: true,
                    toCountry: true
                },
                contact_data: {
                    'adult/0/firstName': true,
                    'adult/0/lastName': true,
                    'adult/0/email': true,
                    'adult/0/phone': true
                },
                tickets: {
                    ticketType: true,
                    noOfAdults: true,
                    noOfKids: true
                    //more added dynamically
                },
                payment: {
                    paymentType: true,
                    invoice: true,
                    payAddrCountry: true,
                    payAddrPostalCode: true,
                    payAddrCity: true,
                    payAddrStreet: true,
                    payAddrNumber: true,
                    payAddrVatId: true,
                    payAddrCompanyName: true
                },
                additional_info: {
                    additionalLuggage: true,
                    additionalLuggageNote: true,
                    note: true,
                    regulationsAccepted: true
                }
            },
            fieldsState: {
                addressFrom: {
                    error: false,
                    infoText: ""
                },
                addressTo: {
                    error: false,
                    infoText: ""
                },
                date: {
                    error: false,
                    infoText: ""
                },
                fromStreet: {
                    error: false,
                    infoText: ""
                },
                fromNumber: {
                    error: false,
                    infoText: ""
                },
                fromCity: {
                    error: false,
                    infoText: ""
                },
                fromPostalCode: {
                    error: false,
                    infoText: ""
                },
                fromCountry: {
                    error: false,
                    infoText: ""
                },
                toStreet: {
                    error: false,
                    infoText: ""
                },
                toNumber: {
                    error: false,
                    infoText: ""
                },
                toCity: {
                    error: false,
                    infoText: ""
                },
                toPostalCode: {
                    error: false,
                    infoText: ""
                },
                toCountry: {
                    error: false,
                    infoText: ""
                },
                'adult/0/firstName': {
                    error: false,
                    infoText: ""
                },
                'adult/0/lastName': {
                    error: false,
                    infoText: ""
                },
                'adult/0/email': {
                    error: false,
                    infoText: ""
                },
                'adult/0/phone': {
                    error: false,
                    infoText: ""
                },
                additionalLuggage: {
                    error: false,
                    infoText: ""
                },
                additionalLuggageNote: {
                    error: false,
                    infoText: ""
                },
                note: {
                    error: false,
                    infoText: ""
                },
                ticketType: {
                    error: false,
                    infoText: ""
                },
                noOfAdults: {
                    error: false,
                    infoText: ""
                },
                noOfKids: {
                    error: false,
                    infoText: ""
                },
                paymentType: {
                    error: false,
                    infoText: ""
                },
                invoice: {
                    error: false,
                    infoText: ""
                },
                payAddrCountry: {
                    error: false,
                    infoText: ""
                },
                payAddrPostalCode: {
                    error: false,
                    infoText: ""
                },
                payAddrCity: {
                    error: false,
                    infoText: ""
                },
                payAddrStreet: {
                    error: false,
                    infoText: ""
                },
                payAddrNumber: {
                    error: false,
                    infoText: ""
                },
                payAddrVatId: {
                    error: false,
                    infoText: ""
                },
                payAddrCompanyName: {
                    error: false,
                    infoText: ""
                },
                regulationsAccepted: {
                    error: false,
                    infoText: ""
                }
                //users
                //`kidsAge${index}` added dynamically
            },
            routeInfoDialogOpen: false,
            submitRequestDialogOpen: false,
            luggageInfoDialogOpen: false
        };
        this.checkRoute = this.checkRoute.bind(this);
        this.handlesSnackClose = this.handlesSnackClose.bind(this);
        this.setAddressFrom = this.setAddressFrom.bind(this);
        this.setAddressTo = this.setAddressTo.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.submitRequest = this.submitRequest.bind(this);
        this.inputChange = this.inputChange.bind(this);
        this.closeRouteInfoDialog = this.closeRouteInfoDialog.bind(this);
        this.nextStep = this.nextStep.bind(this);
        this.validateCurrentStep = this.validateCurrentStep.bind(this);
        this.closeSubmitRequestDialog = this.closeSubmitRequestDialog.bind(this);
        this.closeLuggageInfoDialog = this.closeLuggageInfoDialog.bind(this);
        this.openLuggageInfoDialog = this.openLuggageInfoDialog.bind(this);
        this.onFieldFocus = this.onFieldFocus.bind(this);
        this.validateEmail = this.validateEmail.bind(this);
        if (!routeInfo) {
            this.loadFormData(loadFromMemoryExcept);
        }
    }
    closeRouteInfoDialog() {
        this.setState({
            routeInfoDialogOpen: false
        });
        window.scrollTo(0, 0);
    }
    closeSubmitRequestDialog(success) {
        this.setState({
            submitRequestDialogOpen: false
        });
        if (success) {
            this.props.historyPush("/");
        }
    }
    closeLuggageInfoDialog() {
        this.setState({
            luggageInfoDialogOpen: false
        });
    }
    openLuggageInfoDialog(event) {
        event.preventDefault();
        this.setState({
            luggageInfoDialogOpen: true
        });
    }
    loadFormData(except) {
        const clonsePromise = import('lodash/clone');
        const cookiePromise = import('js-cookie');
        Promise.all([clonsePromise, cookiePromise]).then(function (imports) {
            let cookie;
            try {
                const Clone = imports[0].default;
                const Cookies = imports[1].default;
                cookie = Cookies.get('form-data');
                if (cookie) {
                    const fieldsValues = Clone(this.state.fieldsValues);
                    const fieldsState = Clone(this.state.fieldsState);
                    const stepFields = Clone(this.state.stepFields);
                    const savedFieldsValues = JSON.parse(cookie);
                    if (savedFieldsValues && savedFieldsValues.date) {
                        savedFieldsValues.date = new Date(savedFieldsValues.date);
                    }
                    if (!except['noOfAdults'] && savedFieldsValues['noOfAdults'] !== null && savedFieldsValues['noOfAdults'] !== undefined) {
                        fieldsValues.noOfAdults = savedFieldsValues['noOfAdults'];
                    }
                    if (!except['noOfKids'] && savedFieldsValues['noOfKids'] !== null && savedFieldsValues['noOfKids'] !== undefined) {
                        fieldsValues.noOfKids = savedFieldsValues['noOfKids'];
                    }
                    if (!isNaN(fieldsValues.noOfAdults) && fieldsValues.noOfAdults > 1) {
                        const times = fieldsValues.noOfAdults;
                        for (let i = 1; i < times; i++) {
                            this.addUserFields("adult", i, fieldsValues, fieldsState, stepFields);
                        }
                    }
                    if (!isNaN(fieldsValues.noOfKids) && fieldsValues.noOfKids > 0) {
                        const times = fieldsValues.noOfKids;
                        for (let i = 0; i < times; i++) {
                            this.addUserFields("kid", i, fieldsValues, fieldsState, stepFields);
                        }
                    }
                    for (const name in fieldsValues) {
                        if (except[name]) {
                            continue;
                        }
                        if (Object.prototype.hasOwnProperty.call(savedFieldsValues, name)) {
                            fieldsValues[name] = savedFieldsValues[name];
                        }
                    }
                    this.setState({
                        fieldsValues: fieldsValues,
                        fieldsState: fieldsState,
                        stepFields: stepFields
                    });
                }
            }
            catch (error) {
                console.error("something went wrong during read of form data cookie");
                console.error(error);
                console.error(cookie);
            }
        }.bind(this));
    }
    saveFormData() {
        import('js-cookie').then((Cookie) => {
            const Cookies = Cookie.default;
            this.getFormDataJson().then((data) => {
                Cookies.set('form-data', JSON.stringify(data));
            });
        });
    }
    getFormDataJson() {
        return import('lodash/clone').then((_Clone) => {
            const Clone = _Clone.default;
            return Clone(this.state.fieldsValues);
        });
    }
    checkRoute() {
        import('lodash/clone').then((_Clone) => {
            const Clone = _Clone.default;
            const oRouteHelper = new ServiceHelper();
            const addressFrom = this.state.fieldsValues.addressFrom;
            const addressFromText = addressFrom ? addressFrom.description : null;
            const addressTo = this.state.fieldsValues.addressTo;
            const addressToText = addressTo ? addressTo.description : null;
            const date = this.state.fieldsValues.date;
            let error = false;
            const fieldsState = Clone(this.state.fieldsState);
            if (addressFromText === null || addressFromText === undefined || addressFromText === "") {
                error = true;
                fieldsState.addressFrom.error = true;
                fieldsState.addressFrom.infoText = "Wpisz pełen adres";
            }
            else {
                fieldsState.addressFrom.error = false;
                fieldsState.addressFrom.infoText = "";
            }
            if (addressToText === null || addressToText === undefined || addressToText === "") {
                error = true;
                fieldsState.addressTo.error = true;
                fieldsState.addressTo.infoText = "Wpisz pełen adres";
            }
            else {
                fieldsState.addressTo.error = false;
                fieldsState.addressTo.infoText = "";
            }
            if (date === null || date === undefined || date.toString() === "Invalid Date") {
                error = true;
                fieldsState.date.error = true;
                fieldsState.date.infoText = "Wybierz datę";
            }
            else {
                fieldsState.date.error = false;
                fieldsState.date.infoText = "";
            }
            this.setState({
                fieldsState: fieldsState
            });
            if (error) {
                return;
            }
            this.setState({
                busy: true
            });
            const responsePromise = oRouteHelper.getRouteInfo(addressFromText, addressToText);
            responsePromise.then(function (responseData) {
                this.props.setNewRouteFormData({
                    addressFrom: addressFrom,
                    addressTo: addressTo,
                    date: date,
                    routeInfo: responseData
                });
                this.setState({
                    busy: false,
                    routeInfoDialogOpen: true
                });
            }.bind(this)).catch(function (text) {
                this.setState({
                    snackOpen: true,
                    snackText: text,
                    busy: false
                });
            }.bind(this));
        });
    }
    setAddressFrom(newValue) {
        const fieldsState = Clone(this.state.fieldsState);
        const fieldsValues = Clone(this.state.fieldsValues);
        if (fieldsState.addressFrom.error && newValue) {
            fieldsState.addressFrom.error = false;
            fieldsState.addressFrom.infoText = "";
        }
        fieldsValues.addressFrom = newValue;
        if (!isEqual(this.state.fieldsState, fieldsState) || !isEqual(this.state.fieldsValues, fieldsValues)) {
            this.setState({
                fieldsValues: fieldsValues,
                fieldsState: fieldsState
            });
            if (newValue) {
                this.saveFormData();
            }
        }
    }
    setAddressTo(newValue) {
        const fieldsState = Clone(this.state.fieldsState);
        const fieldsValues = Clone(this.state.fieldsValues);
        if (newValue) {
            fieldsState.addressTo.error = false;
            fieldsState.addressTo.infoText = "";
        }
        fieldsValues.addressTo = newValue;
        if (!isEqual(this.state.fieldsState, fieldsState) || !isEqual(this.state.fieldsValues, fieldsValues)) {
            this.setState({
                fieldsValues: fieldsValues,
                fieldsState: fieldsState
            });
            if (newValue) {
                this.saveFormData();
            }
        }
    }
    handlesSnackClose() {
        this.setState({
            snackOpen: false
        });
    }
    onDateChange(event) {
        const fieldsState = Clone(this.state.fieldsState);
        const fieldsValues = Clone(this.state.fieldsValues);
        fieldsValues.date = event;
        const validation = this.validateDepartureDate(fieldsValues.date);
        fieldsState.date.error = !validation.valid;
        if (!validation.valid) {
            fieldsState.date.infoText = validation.singleMessage.text;
        }
        else {
            fieldsState.date.infoText = '';
        }
        this.setState({
            fieldsValues: fieldsValues,
            fieldsState: fieldsState
        }, () => {
            if (validation.valid) {
                this.saveFormData();
            }
        });
    }
    validateDepartureDate(date) {
        if (date === null || date === undefined) {
            return {
                valid: false,
                singleMessage: {
                    text: 'Wprowadź datę wyjazdu',
                    type: "Error" /* Error */
                }
            };
        }
        if (!(date instanceof Date) || date.toString() === "Invalid Date") {
            return {
                valid: false,
                singleMessage: {
                    text: 'Wprowadź poprawną datę wyjazdu',
                    type: "Error" /* Error */
                }
            };
        }
        const todayMidnight = new Date();
        todayMidnight.setHours(0, 0, 0, 0);
        //date should be in future
        if (date.getTime() < todayMidnight.getTime()) {
            return {
                valid: false,
                singleMessage: {
                    text: 'Wprowadzona data jest z przeszłości',
                    type: "Error" /* Error */
                }
            };
        }
        return {
            valid: true
        };
    }
    submitRequest() {
        this.validateCurrentStep().then(function (valid) {
            if (!valid) {
                return;
            }
            const serviceHelper = new ServiceHelper();
            const users = [];
            const now = new Date();
            const adultBirthDate = new Date();
            adultBirthDate.setUTCHours(0, 0, 0, 0);
            adultBirthDate.setUTCDate(now.getDate());
            adultBirthDate.setUTCMonth(now.getMonth());
            adultBirthDate.setUTCFullYear(adultBirthDate.getUTCFullYear() - 18);
            const ticketType = this.state.fieldsValues.ticketType;
            for (let i = 0; i < this.state.fieldsValues.noOfAdults; ++i) {
                const phones = [];
                if (this.state.fieldsValues[`adult/${i}/phone`] !== undefined) {
                    phones.push({
                        phone: this.state.fieldsValues[`adult/${i}/phone`],
                        phone_type: "MAIN"
                    });
                }
                users.push({
                    first_name: this.state.fieldsValues[`adult/${i}/firstName`],
                    last_name: this.state.fieldsValues[`adult/${i}/lastName`],
                    email: this.state.fieldsValues[`adult/${i}/email`],
                    date_of_birth: adultBirthDate,
                    date_of_birth_prec: false,
                    phones: phones,
                    main: i === 0
                });
            }
            for (let i = 0; i < this.state.fieldsValues.noOfKids; ++i) {
                const initialDate = new Date(this.state.fieldsValues[`kid/${i}/dateOfBirth`]);
                const dateOfBirth = new Date(Date.UTC(initialDate.getFullYear(), initialDate.getMonth(), initialDate.getDate()));
                users.push({
                    first_name: this.state.fieldsValues[`kid/${i}/firstName`],
                    last_name: this.state.fieldsValues[`kid/${i}/lastName`],
                    date_of_birth: dateOfBirth,
                    date_of_birth_prec: true,
                    main: false
                });
            }
            const departureDateDayOnlyString = formatDate(this.state.fieldsValues.date, 'yyyy-MM-dd');
            const requestData = {
                id: undefined,
                assigned: undefined,
                assigned_to: undefined,
                changed_on: undefined,
                created_on: undefined,
                external_id: undefined,
                payment_done: undefined,
                status: undefined,
                addresses: [
                    {
                        country: this.state.fieldsValues.fromCountry,
                        city: this.state.fieldsValues.fromCity,
                        street: this.state.fieldsValues.fromStreet,
                        street_no: this.state.fieldsValues.fromNumber,
                        postal_code: this.state.fieldsValues.fromPostalCode,
                        address_type: "FROM" /* From */,
                        id: undefined,
                        reservation_id: undefined
                    },
                    {
                        country: this.state.fieldsValues.toCountry,
                        city: this.state.fieldsValues.toCity,
                        street: this.state.fieldsValues.toStreet,
                        street_no: this.state.fieldsValues.toNumber,
                        postal_code: this.state.fieldsValues.toPostalCode,
                        address_type: "TO" /* To */,
                        id: undefined,
                        reservation_id: undefined
                    }
                ],
                departure_date: departureDateDayOnlyString,
                return_departure_date: null,
                addit_luggage: this.state.fieldsValues.additionalLuggage,
                addit_luggage_note: this.state.fieldsValues.additionalLuggageNote,
                note: this.state.fieldsValues.note,
                ticket_type: ticketType,
                users: users,
                payments: [{
                        type: this.state.fieldsValues.paymentType,
                        invoice: this.state.fieldsValues.invoice,
                        amount: null,
                        status: "DRA" /* Draft */,
                        address: undefined,
                        changed_on: undefined,
                        created_on: undefined,
                        currency: undefined,
                        id: undefined,
                        pay_provider_ref: undefined,
                        reservation_id: undefined
                    }]
            };
            if (this.state.fieldsValues.invoice === true) {
                requestData.payments[0].address = {
                    id: undefined,
                    vat_id: this.state.fieldsValues.payAddrVatId,
                    company_name: this.state.fieldsValues.payAddrCompanyName,
                    country: this.state.fieldsValues.payAddrCountry,
                    city: this.state.fieldsValues.payAddrCity,
                    street: this.state.fieldsValues.payAddrStreet,
                    street_no: this.state.fieldsValues.payAddrNumber,
                    postal_code: this.state.fieldsValues.payAddrPostalCode
                };
            }
            const responsePromise = serviceHelper.submitRequest(requestData);
            this.setState({
                busy: true
            });
            responsePromise.then(function (response) {
                this.setState({
                    busy: false,
                    submitRequestDialogOpen: true,
                    submitResponse: response
                });
            }.bind(this)).catch((response) => {
                this.setState({
                    busy: false,
                    submitRequestDialogOpen: true,
                    submitResponse: response
                });
            });
        }.bind(this));
    }
    inputChange(inputName, event) {
        let value;
        switch (inputName) {
            case "additionalLuggage":
            case "invoice":
            case "regulationsAccepted":
                value = event.target.checked;
                break;
            case "ticketType":
                value = event;
                this.props.setNewRouteFormData({
                    ticketType: value
                });
                break;
            default:
                if (/.*dateOfBirth/.test(inputName)) {
                    value = event;
                }
                else {
                    value = event.target.value;
                }
        }
        import('lodash/clone').then(function (valueInt, _Clone) {
            const Clone = _Clone.default;
            const fieldsValues = Clone(this.state.fieldsValues);
            const fieldsState = Clone(this.state.fieldsState);
            const stepFields = Clone(this.state.stepFields);
            //values
            switch (inputName) {
                default:
                    if (!Object.prototype.hasOwnProperty.call(fieldsValues, inputName)) {
                        break;
                    }
                    fieldsValues[inputName] = valueInt;
                    break;
            }
            //state
            switch (inputName) {
                case "fromStreet":
                case "toStreet":
                    // no value required
                    break;
                case "regulationsAccepted":
                    if (value === true) {
                        fieldsState[inputName].error = false;
                        fieldsState[inputName].infoText = "";
                    }
                    break;
                case "adult/0/email": {
                    const emailValid = this.validateEmail(value);
                    fieldsState[inputName].error = !emailValid.valid;
                    fieldsState[inputName].infoText = emailValid.message;
                    break;
                }
                case "noOfAdults":
                    if (parseInt(value, 10) < 1 || isNaN(parseInt(value, 10))) {
                        fieldsState[inputName].error = true;
                        fieldsState[inputName].infoText = "Wprowadź poprawną liczbę, minimum jeden";
                    }
                    else {
                        fieldsState[inputName].error = false;
                        fieldsState[inputName].infoText = "";
                    }
                    break;
                default:
                    if (!Object.prototype.hasOwnProperty.call(fieldsValues, inputName)) {
                        break;
                    }
                    if (value !== null && value !== undefined && value !== "") {
                        fieldsState[inputName].error = false;
                        fieldsState[inputName].infoText = "";
                    }
                    break;
            }
            let previousNoOfAdults = parseInt(this.state.fieldsValues.noOfAdults, 10);
            if (isNaN(previousNoOfAdults)) {
                previousNoOfAdults = 0;
            }
            if (inputName === "noOfAdults") {
                let currentValue = parseInt(valueInt, 10);
                if (isNaN(currentValue)) {
                    currentValue = 0;
                }
                const compareNo = currentValue - previousNoOfAdults;
                if (compareNo > 0) {
                    for (let i = 0; i < compareNo; ++i) {
                        this.addUserFields("adult", previousNoOfAdults + i, fieldsValues, fieldsState, stepFields);
                    }
                }
                else if (compareNo < 0) {
                    for (let i = 0; i > compareNo; --i) {
                        this.deleteUserFields("adult", previousNoOfAdults + i - 1, fieldsValues, fieldsState, stepFields);
                    }
                }
            }
            let previousNoOfKids = parseInt(this.state.fieldsValues.noOfKids, 10);
            if (isNaN(previousNoOfKids)) {
                previousNoOfKids = 0;
            }
            if (inputName === "noOfKids") {
                let currentValue = parseInt(valueInt, 10);
                if (isNaN(currentValue)) {
                    currentValue = 0;
                }
                const compareNo = currentValue - previousNoOfKids;
                if (compareNo > 0) {
                    for (let i = 0; i < compareNo; ++i) {
                        this.addUserFields("kid", previousNoOfKids + i, fieldsValues, fieldsState, stepFields);
                    }
                }
                else if (compareNo < 0) {
                    for (let i = 0; i > compareNo; --i) {
                        this.deleteUserFields("kid", previousNoOfKids + i - 1, fieldsValues, fieldsState, stepFields);
                    }
                }
            }
            //set payment type as controlled if ticket type two directions
            if (inputName === "ticketType") {
                if (value === "TWO_D" /* TwoDirections */ && fieldsValues.paymentType === "DRIVER" /* ToDriver */) {
                    fieldsValues.paymentType = "CONTROLLED" /* Controlled */;
                    fieldsState.paymentType.error = false;
                    fieldsState.paymentType.infoText = "";
                }
            }
            this.setState({
                fieldsValues: fieldsValues,
                fieldsState: fieldsState,
                stepFields: stepFields
            });
        }.bind(this, value));
    }
    addUserFields(pathPrefix, index, fieldsValues, fieldsState, stepFields) {
        if (pathPrefix === "adult" && index === 0) {
            return;
        }
        fieldsValues[`${pathPrefix}/${index}/firstName`] = "";
        fieldsValues[`${pathPrefix}/${index}/lastName`] = "";
        if (pathPrefix === "kid") {
            fieldsValues[`${pathPrefix}/${index}/dateOfBirth`] = null;
        }
        fieldsState[`${pathPrefix}/${index}/firstName`] = {
            error: false,
            infoText: ""
        };
        fieldsState[`${pathPrefix}/${index}/lastName`] = {
            error: false,
            infoText: ""
        };
        if (pathPrefix === "kid") {
            fieldsState[`${pathPrefix}/${index}/dateOfBirth`] = {
                error: false,
                infoText: ""
            };
        }
        stepFields["tickets"][`${pathPrefix}/${index}/firstName`] = true;
        stepFields["tickets"][`${pathPrefix}/${index}/lastName`] = true;
        if (pathPrefix === "kid") {
            stepFields["tickets"][`${pathPrefix}/${index}/dateOfBirth`] = true;
        }
    }
    deleteUserFields(pathPrefix, index, fieldsValues, fieldsState, stepFields) {
        if (pathPrefix === "adult" && index === 0) {
            return;
        }
        delete fieldsValues[`${pathPrefix}/${index}/firstName`];
        delete fieldsValues[`${pathPrefix}/${index}/lastName`];
        if (pathPrefix === "kid") {
            delete fieldsValues[`${pathPrefix}/${index}/dateOfBirth`];
        }
        delete fieldsState[`${pathPrefix}/${index}/firstName`];
        delete fieldsState[`${pathPrefix}/${index}/lastName`];
        if (pathPrefix === "kid") {
            delete fieldsState[`${pathPrefix}/${index}/dateOfBirth`];
        }
        delete stepFields["tickets"][`${pathPrefix}/${index}/firstName`];
        delete stepFields["tickets"][`${pathPrefix}/${index}/lastName`];
        if (pathPrefix === "kid") {
            delete stepFields["tickets"][`${pathPrefix}/${index}/dateOfBirth`];
        }
    }
    nextStep() {
        this.validateCurrentStep().then(valid => {
            if (!valid) {
                return;
            }
            this.props.nextStep();
        });
    }
    offset(el) {
        const rect = el.getBoundingClientRect();
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    }
    onFieldFocus(inputName) {
        let offsetTop;
        switch (inputName) {
            case "addressTo":
                if (this.props.mediaSize !== "xs") {
                    return;
                }
                offsetTop = this.offset(document.getElementById('input-address-to')).top;
                window.scrollTo(0, offsetTop - 40);
                break;
            case "addressFrom":
                if (this.props.mediaSize !== "xs") {
                    return;
                }
                offsetTop = this.offset(document.getElementById('input-address-from')).top;
                window.scrollTo(0, offsetTop - 40);
                break;
        }
    }
    validateEmail(email) {
        const result = {
            valid: true,
            message: ""
        };
        if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            result.valid = false;
            result.message = "Adres email wydaje się być nieprawidłowy";
        }
        return result;
    }
    validateCurrentStep() {
        return import('lodash/cloneDeep').then((_Clone) => {
            const Clone = _Clone.default;
            const fields = this.state.stepFields[this.props.activeStep];
            const fieldsState = Clone(this.state.fieldsState);
            let valid = true;
            switch (this.props.activeStep) {
                case "address":
                case "contact_data":
                case "tickets":
                case "payment":
                    for (const fieldName in fields) {
                        if (fieldName === "fromStreet" || fieldName === "toStreet" || fieldName === "invoice") {
                            continue;
                        }
                        else if (this.props.activeStep === "payment" && /payAddr.*/.test(fieldName) && this.state.fieldsValues.invoice === false) {
                            continue;
                        }
                        if (fieldName === 'date') {
                            const dateValidationResult = this.validateDepartureDate(this.state.fieldsValues[fieldName]);
                            fieldsState[fieldName].error = !dateValidationResult.valid;
                            if (fieldsState[fieldName].error) {
                                fieldsState[fieldName].infoText = dateValidationResult.singleMessage.text;
                                valid = false;
                            }
                            continue;
                        }
                        if (fieldName === "noOfAdults" || fieldName === "noOfKids") {
                            if (isNaN(this.state.fieldsValues[fieldName])) {
                                fieldsState[fieldName].error = true;
                                fieldsState[fieldName].infoText = "Wprowadź cyfrę";
                                valid = false;
                            }
                        }
                        if (this.state.fieldsValues[fieldName] === null || this.state.fieldsValues[fieldName] === undefined || this.state.fieldsValues[fieldName] === "") {
                            fieldsState[fieldName].error = true;
                            fieldsState[fieldName].infoText = "Wprowadź wartość";
                            valid = false;
                        }
                        if (fieldName === "adult/0/email") {
                            const emailValid = this.validateEmail(this.state.fieldsValues[fieldName]);
                            fieldsState[fieldName].error = !emailValid.valid;
                            fieldsState[fieldName].infoText = emailValid.message;
                            if (!emailValid.valid) {
                                valid = false;
                            }
                        }
                    }
                    break;
                case "additional_info":
                    if (this.state.fieldsValues["regulationsAccepted"] !== true) {
                        fieldsState["regulationsAccepted"].error = true;
                        fieldsState["regulationsAccepted"].infoText = "Oświadczenie jest wymagane";
                        valid = false;
                    }
            }
            this.setState({
                fieldsState: fieldsState
            });
            if (valid) {
                this.saveFormData();
            }
            return valid;
        });
    }
    render() {
        const routeInfoData = this.props.routeFormData && this.props.routeFormData.routeInfo ? this.props.routeFormData.routeInfo : null;
        return (React.createElement("div", { id: "route-form-container", className: this.props.className },
            React.createElement("form", { id: "route-form", className: styles.form },
                React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].addressFrom },
                    React.createElement(FormControl, { margin: "normal", fullWidth: this.props.formControlsfullWidth },
                        React.createElement(Typography, { variant: "h6" }, "Odjazd"),
                        React.createElement(PlaceInput, { id: "input-address-from", setParentValue: this.setAddressFrom, address: this.state.fieldsValues.addressFrom, error: this.state.fieldsState.addressFrom.error, helperText: this.state.fieldsState.addressFrom.infoText, onFocus: this.onFieldFocus.bind(this, 'addressFrom') }))),
                React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].addressTo },
                    React.createElement(FormControl, { margin: "normal", fullWidth: this.props.formControlsfullWidth },
                        React.createElement(Typography, { variant: "h6" }, "Przyjazd"),
                        React.createElement(PlaceInput, { id: "input-address-to", setParentValue: this.setAddressTo, address: this.state.fieldsValues.addressTo, error: this.state.fieldsState.addressTo.error, helperText: this.state.fieldsState.addressTo.infoText, onFocus: this.onFieldFocus.bind(this, 'addressTo') }))),
                React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].date && this.props.activeStep === 'basic_form' },
                    React.createElement(FormControl, { margin: "normal", fullWidth: this.props.formControlsfullWidth },
                        React.createElement(Typography, { variant: "h6" }, "Data wyjazdu"),
                        React.createElement(MuiPickersUtilsProvider, { utils: DateFnsIO, locale: plLocale },
                            React.createElement(KeyboardDatePicker, { format: "dd.MM.yyyy", disablePast: true, onChange: this.onDateChange, value: this.state.fieldsValues.date, placeholder: "Wybierz dat\u0119", okLabel: "Ok", clearLabel: "Wyczy\u015B\u0107", cancelLabel: "Anuluj", error: this.state.fieldsState.date.error, helperText: this.state.fieldsState.date.infoText })))),
                React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].fromStreet },
                    React.createElement(Typography, { variant: "h6" }, "Odjazd")),
                React.createElement(Grid, { container: true, direction: "row" },
                    React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                        React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].fromStreet },
                            React.createElement(TextField, { style: { paddingRight: "0.5em" }, label: "Ulica", value: this.state.fieldsValues.fromStreet, onChange: this.inputChange.bind(this, "fromStreet"), fullWidth: true, margin: "normal", error: this.state.fieldsState.fromStreet.error, helperText: this.state.fieldsState.fromStreet.infoText, inputProps: { autoComplete: "street" } }))),
                    React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                        React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].fromNumber },
                            React.createElement(TextField, { label: "Numer Domu / Mieszkania", value: this.state.fieldsValues.fromNumber, onChange: this.inputChange.bind(this, "fromNumber"), fullWidth: true, margin: "normal", error: this.state.fieldsState.fromNumber.error, helperText: this.state.fieldsState.fromNumber.infoText })))),
                React.createElement(Grid, { container: true, direction: "row" },
                    React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                        React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].fromPostalCode },
                            React.createElement(TextField, { style: { paddingRight: "0.5em" }, label: "Kod Pocztowy", value: this.state.fieldsValues.fromPostalCode, onChange: this.inputChange.bind(this, "fromPostalCode"), fullWidth: true, margin: "normal", error: this.state.fieldsState.fromPostalCode.error, helperText: this.state.fieldsState.fromPostalCode.infoText }))),
                    React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                        React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].fromCity },
                            React.createElement(TextField, { label: "Miejscowo\u015B\u0107", value: this.state.fieldsValues.fromCity, onChange: this.inputChange.bind(this, "fromCity"), fullWidth: true, margin: "normal", error: this.state.fieldsState.fromCity.error, helperText: this.state.fieldsState.fromCity.infoText })))),
                React.createElement(Grid, { container: true, direction: "row" },
                    React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                        React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].fromCountry },
                            React.createElement(TextField, { label: "Kraj", value: this.state.fieldsValues.fromCountry, onChange: this.inputChange.bind(this, "fromCountry"), fullWidth: true, margin: "normal", error: this.state.fieldsState.fromCountry.error, helperText: this.state.fieldsState.fromCountry.infoText })))),
                React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].toStreet },
                    React.createElement(Typography, { variant: "h6", style: { marginTop: "2em" } }, "Przyjazd")),
                React.createElement(Grid, { container: true, direction: "row" },
                    React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                        React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].toStreet },
                            React.createElement(TextField, { style: { paddingRight: "0.5em" }, label: "Ulica", value: this.state.fieldsValues.toStreet, onChange: this.inputChange.bind(this, "toStreet"), fullWidth: true, margin: "normal", error: this.state.fieldsState.toStreet.error, helperText: this.state.fieldsState.toStreet.infoText }))),
                    React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                        React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].toNumber },
                            React.createElement(TextField, { label: "Numer Domu / Mieszkania", value: this.state.fieldsValues.toNumber, onChange: this.inputChange.bind(this, "toNumber"), fullWidth: true, margin: "normal", error: this.state.fieldsState.toNumber.error, helperText: this.state.fieldsState.toNumber.infoText })))),
                React.createElement(Grid, { container: true, direction: "row" },
                    React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                        React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].toPostalCode },
                            React.createElement(TextField, { style: { paddingRight: "0.5em" }, label: "Kod Pocztowy", value: this.state.fieldsValues.toPostalCode, onChange: this.inputChange.bind(this, "toPostalCode"), fullWidth: true, margin: "normal", error: this.state.fieldsState.toPostalCode.error, helperText: this.state.fieldsState.toPostalCode.infoText }))),
                    React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                        React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].toCity },
                            React.createElement(TextField, { label: "Miejscowo\u015B\u0107", value: this.state.fieldsValues.toCity, onChange: this.inputChange.bind(this, "toCity"), fullWidth: true, margin: "normal", error: this.state.fieldsState.toCity.error, helperText: this.state.fieldsState.toCity.infoText })))),
                React.createElement(Grid, { container: true, direction: "row" },
                    React.createElement(Grid, { item: true, xs: 12, sm: 6 },
                        React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].toCountry },
                            React.createElement(TextField, { label: "Kraj", value: this.state.fieldsValues.toCountry, onChange: this.inputChange.bind(this, "toCountry"), fullWidth: true, margin: "normal", error: this.state.fieldsState.toCountry.error, helperText: this.state.fieldsState.toCountry.infoText })))),
                React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].date && this.props.activeStep !== 'basic_form' },
                    React.createElement(Grid, { container: true, direction: "row" },
                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                            React.createElement(FormControl, { margin: "normal", fullWidth: this.props.formControlsfullWidth },
                                React.createElement(Typography, { variant: "h6", style: { marginTop: "2em" } }, "Data wyjazdu"),
                                React.createElement(MuiPickersUtilsProvider, { utils: DateFnsIO, locale: plLocale },
                                    React.createElement(KeyboardDatePicker, { format: "dd.MM.yyyy", disablePast: true, onChange: this.onDateChange, value: this.state.fieldsValues.date, placeholder: "Wybierz dat\u0119", okLabel: "Ok", clearLabel: "Wyczy\u015B\u0107", cancelLabel: "Anuluj", error: this.state.fieldsState.date.error, helperText: this.state.fieldsState.date.infoText })))))),
                React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep]['adult/0/firstName'] },
                    React.createElement(FormControl, { margin: "normal", fullWidth: this.props.formControlsfullWidth },
                        React.createElement(TextField, { fullWidth: this.props.formControlsfullWidth, label: "Imi\u0119", onChange: this.inputChange.bind(this, "adult/0/firstName"), placeholder: "Wpisz imi\u0119", value: this.state.fieldsValues['adult/0/firstName'], error: this.state.fieldsState['adult/0/firstName'].error, helperText: this.state.fieldsState['adult/0/firstName'].infoText, inputProps: {
                                autoComplete: "given-name"
                            } }))),
                React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep]['adult/0/lastName'] },
                    React.createElement(FormControl, { margin: "normal", fullWidth: this.props.formControlsfullWidth },
                        React.createElement(TextField, { fullWidth: this.props.formControlsfullWidth, label: "Nazwisko", onChange: this.inputChange.bind(this, "adult/0/lastName"), placeholder: "Wpisz nazwisko", value: this.state.fieldsValues['adult/0/lastName'], error: this.state.fieldsState['adult/0/lastName'].error, helperText: this.state.fieldsState['adult/0/lastName'].infoText, inputProps: {
                                autoComplete: "family-name"
                            } }))),
                React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep]['adult/0/email'] },
                    React.createElement(FormControl, { margin: "normal", fullWidth: this.props.formControlsfullWidth },
                        React.createElement(TextField, { fullWidth: this.props.formControlsfullWidth, label: "Adres email", onChange: this.inputChange.bind(this, "adult/0/email"), placeholder: "Wpisz adres email", value: this.state.fieldsValues['adult/0/email'], error: this.state.fieldsState['adult/0/email'].error, helperText: this.state.fieldsState['adult/0/email'].infoText, inputProps: {
                                autoComplete: "email"
                            } }))),
                React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep]['adult/0/phone'] },
                    React.createElement(FormControl, { margin: "normal", fullWidth: this.props.formControlsfullWidth },
                        React.createElement(TextField, { fullWidth: this.props.formControlsfullWidth, label: "Telefon", onChange: this.inputChange.bind(this, "adult/0/phone"), placeholder: "Wpisz telefon kontaktowy", value: this.state.fieldsValues['adult/0/phone'], error: this.state.fieldsState['adult/0/phone'].error, helperText: this.state.fieldsState['adult/0/phone'].infoText, inputProps: {
                                autoComplete: "phone"
                            } }))),
                React.createElement(Payment, { stepFields: this.state.stepFields, activeStep: this.props.activeStep, inputChange: this.inputChange, this: this, fieldsState: this.state.fieldsState, fieldsValues: this.state.fieldsValues }),
                React.createElement(Tickets, { routeInfoData: routeInfoData, stepFields: this.state.stepFields, activeStep: this.props.activeStep, inputChange: this.inputChange, this: this, ticketType: this.state.fieldsValues.ticketType, fieldsState: this.state.fieldsState, fieldsValues: this.state.fieldsValues, noOfKids: this.state.fieldsValues.noOfKids, noOfAdults: this.state.fieldsValues.noOfAdults }),
                React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].additionalLuggage },
                    React.createElement(Grid, { container: true, direction: "row", alignItems: "center" },
                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: this.state.fieldsValues.additionalLuggage, name: "gilad", onChange: this.inputChange.bind(this, "additionalLuggage") }), label: "Potrzebuj\u0119 dodatkowy baga\u017C" })),
                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                            React.createElement(Link, { onClick: this.openLuggageInfoDialog, component: "button" }, "informacja o baga\u017Cu podstawowym")))),
                React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].additionalLuggageNote &&
                        this.state.fieldsValues.additionalLuggage },
                    React.createElement(FormControl, { margin: "normal", fullWidth: this.props.formControlsfullWidth },
                        React.createElement(TextField, { fullWidth: this.props.formControlsfullWidth, label: "Opis dodatkowego baga\u017Cu", onChange: this.inputChange.bind(this, "additionalLuggageNote"), placeholder: "Opisz dodatkowy baga\u017C", value: this.state.fieldsValues.additionalLuggageNote, multiline: true }))),
                React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].note },
                    React.createElement(FormControl, { margin: "normal", fullWidth: this.props.formControlsfullWidth },
                        React.createElement(TextField, { fullWidth: this.props.formControlsfullWidth, label: "Notatka", onChange: this.inputChange.bind(this, "note"), placeholder: "Je\u015Bli chcesz napisz co\u015B wi\u0119cej", value: this.state.fieldsValues.note, multiline: true }))),
                React.createElement(Control, { visible: this.state.stepFields[this.props.activeStep].regulationsAccepted },
                    React.createElement(FormControl, { style: { marginTop: "1em" } },
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: this.state.fieldsValues.regulationsAccepted, name: "regulations", onChange: this.inputChange.bind(this, "regulationsAccepted"), className: this.state.fieldsState.regulationsAccepted.error ? styles['error'] : "" }), label: (React.createElement("span", null,
                                "O\u015Bwiadczam, \u017Ce zapozna\u0142em si\u0119 z ",
                                React.createElement(Link, { href: `${BASE_HREF}regulamin`, target: "_blank" }, "regulaminem"),
                                " i ",
                                React.createElement(Link, { href: `${BASE_HREF}polityka_prywatnosci`, target: "_blank" }, "polityk\u0105 prywatno\u015Bci"),
                                " serwisu translison.pl")) }),
                        this.state.fieldsState.regulationsAccepted.error ? (React.createElement(FormHelperText, { className: styles['error'] },
                            this.state.fieldsState.regulationsAccepted.infoText,
                            ".")) : null)),
                React.createElement("div", { className: styles["btn-container"] },
                    React.createElement(CircularProgress, { color: "secondary", className: styles['btn-busy-indicator'], style: { position: "absolute", display: this.state.busy ? "block" : "none" } }),
                    React.createElement(Control, { visible: this.props.details && this.props.activeStepIndex > 0 },
                        React.createElement(Button, { variant: "contained", color: "primary", onClick: this.props.previousStep, disabled: this.state.busy, style: { marginRight: "2em" } }, "WSTECZ")),
                    React.createElement(Control, { visible: this.props.details && this.props.activeStepIndex < (this.props.steps.length - 1) },
                        React.createElement(Button, { variant: "contained", color: "primary", onClick: this.nextStep, disabled: this.state.busy }, "DALEJ")),
                    React.createElement(Control, { visible: this.props.details && this.props.activeStepIndex === (this.props.steps.length - 1) },
                        React.createElement(Button, { variant: "contained", color: "primary", onClick: this.submitRequest, disabled: this.state.busy }, "WY\u015ALIJ PRO\u015AB\u0118 O REZERWACJ\u0118")),
                    React.createElement(Control, { visible: !this.props.details },
                        React.createElement(Button, { variant: "contained", color: "primary", onClick: this.checkRoute, disabled: this.state.busy || DISABLE_RESERVATION === 'true' }, "SPRAWD\u0179 PO\u0141\u0104CZENIE")))),
            React.createElement(Snackbar, { anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center'
                }, open: this.state.snackOpen, onClose: this.handlesSnackClose, autoHideDuration: 5000, message: this.state.snackText }),
            React.createElement(RouteInfoDialog, { open: this.state.routeInfoDialogOpen, close: this.closeRouteInfoDialog, routeInfoData: routeInfoData, routeFormData: this.props.routeFormData, historyPush: this.props.historyPush, mediaSize: this.props.mediaSize, this: this, inputChange: this.inputChange }),
            React.createElement(SubmitRequestDialog, { open: this.state.submitRequestDialogOpen, close: this.closeSubmitRequestDialog, mediaSize: this.props.mediaSize, submitResponse: this.state.submitResponse }),
            React.createElement(LuggageInfoDialog, { open: this.state.luggageInfoDialogOpen, close: this.closeLuggageInfoDialog, mediaSize: this.props.mediaSize })));
    }
}
export default RouteForm;
