class Request {

    constructor() {
            
    }

    get(url) {

        return new Promise(function(resolve, reject) {

            try {

                let sInnerUrl = encodeURI(url);

                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function() {
                    if (this.readyState == 4) {
                        if(this.status == 200) {
                            resolve(this.responseText);
                        } else {
                            reject(this.responseText);
                        }
                    }
                };
                xhttp.open("GET", sInnerUrl, true);
                xhttp.send();

            } catch(oError) {
                reject(oError);
            }

        });

    }

    post(url, data) {

        return new Promise(function(resolve, reject) {
            try {

                let sInnerUrl = encodeURI(url);

                var xhttp = new XMLHttpRequest();
                xhttp.onreadystatechange = function() {
                    if (this.readyState == 4) {
                        if(/2../.test(this.status.toString())) {
                            resolve(this.responseText);
                        } else {
                            reject(this.responseText);
                        }
                    }
                };
                xhttp.open("POST", sInnerUrl, true);
                xhttp.setRequestHeader('Content-Type', 'application/json');
                xhttp.setRequestHeader('Referrer-Policy', 'origin');
                let dataString;

                if(typeof data === "string") {
                    dataString = data;
                } else {
                    dataString = JSON.stringify(data);
                }

                xhttp.send(dataString);

            } catch(oError) {
                reject(oError);
            }

        });

    }

}

export default Request;