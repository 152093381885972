import React, { Component } from "react";

import SectionContainer from "../SectionContainer/SectionContainer.jsx";
import styles from "./Routes.module.scss";
import DirectionsBusOutlinedIcon from '@material-ui/icons/DirectionsBusOutlined';
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";

class Routes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }

        this.scrollToForm = this.scrollToForm.bind(this);

    }

    scrollToForm(withoutAnimation) {
        document.documentElement.scrollIntoView({
            behavior: withoutAnimation ? "auto" : "smooth"
        });
        setTimeout(function() {
            document.getElementById("input-address-from").focus();
        }, 0);
    }

    render() {

        let routeConfig;

        if(this.props.expanded) {
            routeConfig = {name: "main", to: "/"};
        } else {
            routeConfig = {name: "routes", to: "/?sekcja=trasy"};
        }

        return (
            <div id={this.props.id} className={this.props.className + " " + styles["main-container"]}>
                <SectionContainer 
                    title="Trasy" 
                    icon={<DirectionsBusOutlinedIcon
                        color="primary"
                        style={{display: "block"}}
                        fontSize="inherit"
                    />}
                    expandable={true}
                    expanded={this.props.expanded}
                    routeConfig={routeConfig}>
                        
                    <div>
                        <div id="map" style={{width: "100%"}}>
                        <iframe src="https://www.google.com/maps/d/embed?mid=15ztCT_UnHFQbvZKqdAZ5VItVUpyXlM8&ehbc=2E312F" 
                            width="100%" height="300" frameBorder="0"></iframe>
                        </div>
                        <Typography variant="body1" color="inherit" style={{marginTop: "1em"}}>
                            <span style={{color: "#D76889"}}>Codzienne przejazdy (różowy)</span><br></br>
                            <span style={{color: "#459EDE"}}>Dodatkowe przejazdy (niebieski)​</span>
                        </Typography>
                        <Typography variant="body2" style={{marginTop: "1em"}}>
                            Na mapie powyżej możesz zapoznać się z zasięgiem naszych przewozów, lub użyj przycisku "SPRAWDŹ 
                            ADRESY" i wypełnij formularz.
                        </Typography>
                        <div className={styles["button-container"]}>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={this.scrollToForm}>
                                SPRAWDŹ ADRESY
                            </Button>
                        </div>
                    </div>
                </SectionContainer>
            </div>
        );
    }

}

export default Routes;