import React from "react";

import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';

import Teaser from "../Teaser/Teaser.jsx";

import { Grid } from "@material-ui/core";

import styles from "./TeasersSection.module.scss";

class TeasersSection extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            teasers: [
                {
                    key: 0,
                    title: "TANIE BILETY",
                    subtitle: "od 380zł",
                    icon: <AttachMoneyOutlinedIcon color="inherit" fontSize="inherit"/>,
                    iconColor: "#C6878F"
                },
                {
                    key: 1,
                    title: "ZADOWOLONYCH PASAŻERÓW",
                    subtitle: "500 000",
                    icon: <EmojiEmotionsOutlinedIcon color="inherit" fontSize="inherit"/>,
                    iconColor: "#EF8354"
                },
                {
                    key: 2,
                    title: "Door to Door",
                    subtitle: "Codziennie",
                    icon: <HomeOutlinedIcon color="inherit" fontSize="inherit"/>,
                    iconColor: "#033572"
                },
                {
                    key: 3,
                    title: "DOŚWIADCZENIE",
                    subtitle: "25 lat",
                    icon: <ThumbUpAltOutlinedIcon color="inherit" fontSize="inherit"/>,
                    iconColor: "#27AE60"
                }
            ]
        };

    }

    render() {

        let teasers = this.state.teasers.map((teaser) => (
            <Grid item key={teaser.key} xs={11} sm={5} lg={2} style={{marginTop: "1.5em", marginBottom: "1.5rem"}}>
                <Teaser
                    title={teaser.title}
                    subtitle={teaser.subtitle}
                    icon={teaser.icon}
                    iconColor={teaser.iconColor}
                />
            </Grid>
        ));

        return (

            <Grid container justifyContent="space-around" direction="row" style={{marginTop: "3em"}} alignItems="stretch">
                {teasers}
            </Grid>
            
        );
    }

}

export default TeasersSection;