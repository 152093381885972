class Formatter {
    static getCountryNameFromKeyToForm(countryKey) {
        switch (countryKey) {
            case "PL" /* Poland */:
                return "Polski";
            case "BE" /* Belgium */:
                return "Belgii";
            case "DE" /* Germany */:
                return "Niemiec";
            case "NL" /* Netherlands */:
                return "Holandii";
            default:
                return "";
        }
    }
    static formatPrice(price) {
        try {
            if (price === null || price === undefined) {
                return "";
            }
            const priceString = String(price);
            const wholeNumberPart = priceString.substring(0, priceString.length - 2);
            const decimalPart = priceString.substring(priceString.length - 2);
            if (decimalPart === "00") {
                return `${wholeNumberPart}`;
            }
            return `${wholeNumberPart},${decimalPart}`;
        }
        catch {
            return "";
        }
    }
}
export default Formatter;
