import React, { Component } from "react";

import styles from "./FooterSubsection.module.scss";
import { Typography } from "@material-ui/core";

class FooterSubsection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {

        let Icon;

        Icon = this.props.icon;

        return (
            <div className={styles["main-container"]}>
                <div className={styles["icon-container"]}>
                    {Icon}
                </div>
                <div className={styles["text-container"]}>
                    <Typography variant="body2">
                        {this.props.children}
                    </Typography>
                </div>
            </div>
        );
    }

}

export default FooterSubsection;