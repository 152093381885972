import React from "react";
import { Component } from "react";

import {
    Switch,
    Route
} from "react-router-dom";

import CustomTheme from "../CustomTheme/CustomTheme.jsx";
import { Container } from "@material-ui/core";
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import { Drawer } from '@material-ui/core';

import styles from "./App.module.scss";
import MainView from "../Views/Main/MainView.jsx";
import RouteFormDetailsView from "../Views/RouteFormDetails/RouteFormDetailsView.jsx";
import NavigationMenu from "../Header/Menu/NavigationMenu.jsx";
import FAQView from "../Views/FAQ/LoadableFAQView";
import NewsView from "../Views/News/NewsView.jsx";
import AboutView from "../Views/About/LoadableAboutView";
import PaymentView from "../Views/Payment/LoadablePaymentView";
import LoadablePrivacyView from "../Views/Privacy/LoadablePrivacyView";
import LoadableRulesView from "../Views/Rules/LoadableRulesView";

class App extends Component {

    constructor(props) {

        super(props);
        this.state = {
            drawerOpened: false,
            route: "/",
            sameRouteCount: 0,
            routeFormData: {
                addressFrom: null,
                addressTo: null,
                ticketType: null,
                date: null,
                routeInfo: null
            }
        };

        this.toggleDrawer = this.toggleDrawer.bind(this);
        this.routeMatched = this.routeMatched.bind(this);
        this.scrollToElement = this.scrollToElement.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.saveRouteData = this.saveRouteData.bind(this);
        this.loadRouteData = this.loadRouteData.bind(this);

        if (this.props.routeMatch !== undefined) {

            this.routeMatched(this.props.routeMatch, true);
        
        }

        this.setNewRouteFormData = this.setNewRouteFormData.bind(this);

        this.loadRouteData();

    }

    componentDidUpdate(prevProps) {

        import('lodash/isEqual').then((isEqual) => {

            if(!isEqual.default(prevProps.routeMatch, this.props.routeMatch)) {

                this.routeMatched(this.props.routeMatch);   
            
            }
        
        });
    
    }

    toggleDrawer() {
        
        let drawerOpened = false;

        if(!this.state.drawerOpened) {

            drawerOpened = true;
        
        }

        this.setState({
            drawerOpened: drawerOpened
        });

    }

    routeMatched(routeMatch, duringInitialization) {

        let internalRoute;

        switch(routeMatch.path) {

        case "/":
            switch(routeMatch.params.section) {

            case "trasy":
                internalRoute = "routes";
                break;
            case "cennik":
                internalRoute = "prices";
                break;
            default:
                internalRoute = "main";
                break;
            
            }
            break;
        case "/kontakt":
            internalRoute = "about";
            break;
        case "/maszpytania":
            internalRoute = "questions";
            break;
        case "/aktualnosci":
            internalRoute = "news";
            break;
        default:
            throw new Error("Routing path unknown");
        
        }

        let newState = {
            drawerOpened: false,
            route: internalRoute
        };

        if(duringInitialization) {

            this.state.drawerOpened = newState.drawerOpened;
            this.state.route = newState.route;
        
        } else {

            this.setState(newState);
        
        }

        function blockScrollRestoration() {

            try {

                history.scrollRestoration = "manual";
                setTimeout(function() {

                    history.scrollRestoration = "auto";
                
                }, 5000);
            
            } catch (oError) {
                //do nothing if not supported
            }
        
        }

        switch(internalRoute) {

        case "routes":
            if(duringInitialization) {

                blockScrollRestoration();
            
            }
            this.scrollToElement(internalRoute);
            break;
        case "prices":
            if(duringInitialization) {

                blockScrollRestoration();
            
            }
            this.scrollToElement(internalRoute);
            break;
            // case "route-form-details":
            //     this.props.historyPush("/formularz?addressFrom=" + additionalProperties.addressFrom + 
            //         "&addressTo=" + additionalProperties.addressTo);
            //     break;
        case "questions":
            this.scrollToTop(true);
            break;
        case "news":
            this.scrollToTop(true);
            break;
        case "about":
            this.scrollToTop(true);
            break;
        case "/":
            this.scrollToTop();
            break;
        default:
            break;
        
        }

    }

    scrollToTop(withoutAnimation) {

        setTimeout(function() {

            document.documentElement.scrollIntoView({
                behavior: withoutAnimation ? "auto" : "smooth"
            });
        
        }.bind(this), 0);  
    
    }

    scrollToElement(id) {

        setTimeout(function() {

            document.getElementById(id).scrollIntoView({
                behavior: "smooth"
            });
        
        }.bind(this), 100);        

    }

    saveRouteData(routeData) {

        import('js-cookie').then(function(Cookie) {

            let Cookies = Cookie.default;
    
            //todo
            Cookies.set('route-info', JSON.stringify(routeData));
    
        }.bind(this));

    }

    loadRouteData() {

        let libraries = [];
        libraries.push(import('js-cookie'));
        libraries.push(import('lodash/clone'));

        Promise.all(libraries).then((loadedLibraries) => {

            let Cookies = loadedLibraries[0].default;
            let Clone = loadedLibraries[1].default;

            let routeFormDataClone = Clone(this.state.routeFormData);

            let cookie = Cookies.get('route-info');

            if(cookie) {

                let data = JSON.parse(cookie);

                for(let property in routeFormDataClone) {

                    if(Object.prototype.hasOwnProperty.call(data, property)) {

                        routeFormDataClone[property] = data[property];
                    
                    }
                
                }

                this.setState({
                    routeFormData: routeFormDataClone
                });

            }

        });

    }

    setNewRouteFormData(routeData) {

        import('lodash/clone').then(function(_Clone) {

            const Clone = _Clone.default;

            let routeFormData = Clone(this.state.routeFormData);

            for(let name in routeFormData) {

                if(Object.prototype.hasOwnProperty.call(routeData, name)) {

                    routeFormData[name] = routeData[name];
                
                }
            
            }

            import('lodash/isEqual').then((isEqual) => {

                if(!isEqual.default(this.state.routeFormData, routeFormData)) {

                    this.saveRouteData(routeFormData);
                
                }
            
            });

            this.setState({
                routeFormData: routeFormData
            });

        }.bind(this));

    }

    profilerOnRender(id, phase, actualDuration, baseDuration) {

        console.log(`${id} ${phase} actual:${actualDuration} base: ${baseDuration}`);
    
    }

    render() {

        return (
            
            <CustomTheme>
                <div style={{minHeight: "100vh", display: "flex", flexDirection: "column"}}>

                    <Header openMenu={this.toggleDrawer}/>
                    <Container 
                        className={styles.container}
                        disableGutters={true}>
                        <Switch>
                            <Route path="/formularz">
                                <RouteFormDetailsView
                                    routeFormData={this.state.routeFormData}
                                    historyPush={this.props.historyPush}
                                    mediaSize={this.props.mediaSize}
                                    setNewRouteFormData={this.setNewRouteFormData}
                                />
                            </Route>
                            <Route path="/maszpytania">
                                <FAQView/>
                            </Route>
                            <Route path="/aktualnosci">
                                <NewsView/>
                            </Route>
                            <Route path="/kontakt">
                                <AboutView/>
                            </Route>
                            <Route path="/platnosc/:id">
                                <PaymentView
                                />
                            </Route>
                            <Route path="/polityka_prywatnosci">
                                <LoadablePrivacyView/>
                            </Route>
                            <Route path="/regulamin">
                                <LoadableRulesView/>
                            </Route>
                            <Route path="/">
                                <MainView
                                    route={this.state.route}
                                    setNewRouteFormData={this.setNewRouteFormData}
                                    historyPush={this.props.historyPush}
                                    routeFormData={this.state.routeFormData}
                                    mediaSize={this.props.mediaSize}
                                />
                            </Route>
                        </Switch>
                    </Container>
                    <div style={{flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "flex-end"}}>
                        <Footer/>
                    </div>
                </div>
                <Drawer anchor="left" open={this.state.drawerOpened} onClose={this.toggleDrawer}>
                    <NavigationMenu/>
                </Drawer>
            </CustomTheme>
        );
    
    }

}

export default App;