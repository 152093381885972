import React, { Component } from "react";

import styles from "./NavigationMenu.module.scss";

import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';

import DirectionsBusOutlinedIcon from "@material-ui/icons/DirectionsBusOutlined";
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import PersonPinOutlinedIcon from '@material-ui/icons/PersonPinOutlined';
import AnnouncementOutlinedIcon from '@material-ui/icons/AnnouncementOutlined';
import keysSVG from "../../../../media/svg/keys.svg";

import { Link } from "react-router-dom";


class NavigationMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className={styles["main-container"]}>
                <List component="nav">
                    <Link to="/">
                        <ListItem button>
                            <ListItemText primary="Strona główna" />
                        </ListItem>
                    </Link>
                    <Link to="/?sekcja=trasy">
                        <ListItem button>
                            <ListItemIcon>
                                <DirectionsBusOutlinedIcon
                                    color="primary"
                                    fontSize="default"
                                />
                            </ListItemIcon>
                            <ListItemText primary="Trasy" />
                        </ListItem>
                    </Link>
                    <Link to="/?sekcja=cennik">
                        <ListItem button>
                            <ListItemIcon>
                                <AttachMoneyOutlinedIcon
                                    color="primary"
                                    fontSize="default"
                                />
                            </ListItemIcon>
                            <ListItemText primary="Cennik" />
                        </ListItem>
                    </Link>
                    <Link to="/maszpytania">
                        <ListItem button>
                            <ListItemIcon>
                                <HelpOutlineOutlinedIcon
                                    color="primary"
                                    fontSize="default"
                                />
                            </ListItemIcon>
                            <ListItemText primary="Masz pytania?" />
                        </ListItem>
                    </Link>
                    <Link to="/kontakt">
                        <ListItem button>
                            <ListItemIcon>
                                <PersonPinOutlinedIcon
                                    color="primary"
                                    fontSize="default"
                                />
                            </ListItemIcon>
                            <ListItemText primary="Kontakt" />
                        </ListItem>
                    </Link>
                    <Link to="/aktualnosci">
                        <ListItem button divider={true}>
                            <ListItemIcon>
                                <AnnouncementOutlinedIcon
                                    color="primary"
                                    fontSize="default"
                                />
                            </ListItemIcon>
                            <ListItemText primary="Aktualności" />
                        </ListItem>
                    </Link>
                </List>
            </div>
        );
    }

}

export default NavigationMenu;