import React from "react";

import styles from "./NewsBase.module.scss";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import DateFnsAdapter from '@date-io/date-fns';
import plLocale from 'date-fns/locale/pl';
// import { Link as LinkUI } from '@material-ui/core';

/* globals BACKEND_SERVICE:false */

class NewsBase extends React.Component {

    cardElement;

    constructor(props) {

        super(props);
        this.state = {
            isOverflowing: false,
            contentExpanded: false
        };

        this.momentAdapter = new DateFnsAdapter({ locale: plLocale });
        this.state.dateIO = this.momentAdapter.date(this.props.date);
        this.state.dateFormatted = this.momentAdapter.format(this.state.dateIO, "d MMM, yyyy");

        this.setExpanded = this.setExpanded.bind(this);

    }

    setExpanded() {

        this.setState({
            contentExpanded: !this.state.contentExpanded
        });
    
    }

    componentDidMount() {

        if(this.cardElement) {

            if(this.cardElement.scrollHeight > this.cardElement.clientHeight) {

                this.setState({
                    isOverflowing: true
                });

            }

        }

    }

    render() {

        let textSection;

        if(this.props.text !== null && this.props.text !== "") {

            // let dotsColor = this.state.contentExpanded ? "transparent" : "inherit";

            textSection = (

                <div className={styles['text-container']}>
                    <Typography variant="body2" component="div" style={{marginTop: "0.5em"}}>
                        {this.props.text}
                    </Typography>

                    {/* <div className={styles["expand-container"]}>
                        <span className={styles["three-dots"]} style={{ color: dotsColor }}>...</span>
                    
                        <div className={styles["link-coontainer"]}>
                            <LinkUI
                                component="div"
                                onClick={this.setExpanded}
                                style={{textDecoration: "none", cursor: "pointer"}}>

                                {this.state.contentExpanded ? "zwiń": "pokaż więcej"}

                            </LinkUI>
                        </div>
                    </div> */}
                </div>

            );
        
        }

        let image;

        if(this.props.image) {

            image = (
                <div className={styles['image-container']}>
                    <img src={`${BACKEND_SERVICE}/${this.props.image}`} className={styles["image"]} alt="test"/>
                </div>);
        
        }

        let content;
        let title;

        if(this.props.title && this.props.title !== "") {

            title = <Typography variant={this.props.titleVariant} component="div" className={styles['title-container']}>
                {this.props.title}
            </Typography>;

        }

        content = (
            <div className={styles['content-container']}>
                {image}
                {title}
                {textSection}
            </div>
        );

        return (
            <Grid item xs={11} className={styles["main-container"]}>
                <div className={styles["card"]} ref={(el) => { this.cardElement = el; }}>
                    <div className={styles["card-container"]}>
                        <div className={styles["date-container"]}>
                            <Typography variant="body2">
                                {this.state.dateFormatted}
                            </Typography>
                        </div>

                        {content}

                    </div>
                </div>
            </Grid>
        );
    
    }

}

export default NewsBase;