import React from "react";

class Control extends React.Component {

    constructor(props) {
        //visible
        //busy
        super(props);

        this.state = {
        }
    }

    render() {

        let children = this.props.children;

        if(this.props.visible) {
            children = this.props.children;
        }

        return (
            <div style={{display: this.props.visible ? "block" : "none" }} className={this.props.className}>
                {children}
            </div>
        );

    }

}

export default Control;