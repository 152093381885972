import React from "react";

import { Grid } from "@material-ui/core";
import RouteForm from "../../RouteForm/RouteForm";
import Routes from "../../Routes/Routes.jsx";
import Prices from "../../Prices/Prices";
import SectionContainer from "../../SectionContainer/SectionContainer.jsx";

import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import PersonPinOutlinedIcon from '@material-ui/icons/PersonPinOutlined';
import FacebookLogo from "../../../../media/facebook_logo.png";
import NewsSection from "../../News/NewsSection/NewsSection";
import TeasersSection from "../../TeasersSection/TeasersSection.jsx";

import { Hidden, Typography } from "@material-ui/core";

import mainImage from '../../../../media/wiele_aut_900_601.webp';
import nextImage3 from '../../../../media/jedno_auto_900_600.webp';
import nextImage from '../../../../media/sesja/8a.webp';
import nextImage4 from '../../../../media/sesja/7.webp';

import styles from "./MainView.module.scss";

const carouselInterval = 4000;
let carouselIntervalObject = null;
let carouselInitialized = false;

class MainView extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loadedImages: [

            ],
            currentImageIndex: 0
        };

        this.preloadImage = this.preloadImage.bind(this);
        this.loadNextImages = this.loadNextImages.bind(this);
        this.startCarousel = this.startCarousel.bind(this);
        this.stopCarousel = this.stopCarousel.bind(this);
        this.changeImage = this.changeImage.bind(this);

    }

    componentDidMount() {

        this.startCarousel();

    }

    componentWillUnmount() {

        this.stopCarousel();

    }

    componentDidUpdate() {

        this.initializeCarousel();

    }

    initializeCarousel() {

        if(this.props.mediaSize !== 'xs' && this.props.mediaSize !== 'sm') {

            if(carouselInitialized) {

                return;
    
            }
    
            carouselInitialized = true;

            this.preloadImage(mainImage).then(function(img) {

                var loadedImages = this.state.loadedImages.slice();
                
                loadedImages.push(img);

                this.setState({
                    loadedImages: loadedImages
                });

                this.loadNextImages();

            }.bind(this));

        }

    }

    preloadImage(url) {

        return new Promise(function(resolve) {

            const img = new Image();
            img.onload = function() {

                resolve(img);
            
            }.bind(this);
            img.src = url;

        }.bind(this));

    }

    startCarousel() {

        carouselIntervalObject = setInterval(this.changeImage, carouselInterval);

    }

    stopCarousel() {

        clearInterval(carouselIntervalObject);

    }

    changeImage() {

        var loadedImages = this.state.loadedImages;
        var currentIndex = this.state.currentImageIndex;
        var previousIndex = currentIndex;

        if(loadedImages.length > 1) {

            ++currentIndex;

            if(currentIndex === loadedImages.length) {

                currentIndex = 0;
            
            }

            this.setState({
                currentImageIndex: currentIndex
            });

            let previousDiv = document.getElementById("mainview-image" + previousIndex);
            let currentDiv = document.getElementById("mainview-image" + currentIndex);

            //window was resized to smaller and back probably if no div(s)
            if(previousDiv && currentDiv) {

                currentDiv.classList.add(styles["show"]);
                currentDiv.classList.remove(styles["hide"]);
                previousDiv.classList.add(styles["hide"]);
                previousDiv.classList.remove(styles["show"]);

            }

        }

    }

    loadNextImages() {

        function createDiv(img, counter) {

            var loadedImages = this.state.loadedImages.slice();
            
            loadedImages.push(img);

            var div = document.createElement("div");
            div.setAttribute("id", `mainview-image${counter}`);

            div.classList.add(styles['top-image-image']);
            div.classList.add(styles['hide']);

            div.style["background-image"] = `url(${img.src})`;

            let container = document.getElementById("images-container");
            
            if(container) {

                container.appendChild(div);

                this.setState({
                    loadedImages: loadedImages
                });

            }

        }

        this.preloadImage(nextImage).then(function(img) {

            createDiv.call(this, img, 1);
        
        }.bind(this));
        this.preloadImage(nextImage3).then(function(img) {

            createDiv.call(this, img, 2);
        
        }.bind(this));
        this.preloadImage(nextImage4).then(function(img) {

            createDiv.call(this, img, 3);
        
        }.bind(this));

    }

    render() {

        return (
            <div className={styles["main-container"]}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center">

                    
                    <Grid container justifyContent="space-around" 
                        style={{
                            marginBottom: this.props.mediaSize !== 'xs' ? '4em' : '0em'
                        }}>
                        <Grid item xs={12} md={4} className={styles['route-form']}>
                            <RouteForm 
                                formControlsfullWidth={true}
                                className={styles['section']}
                                setNewRouteFormData={this.props.setNewRouteFormData}
                                historyPush={this.props.historyPush}
                                routeFormData={this.props.routeFormData}
                                mediaSize={this.props.mediaSize}
                                activeStep="basic_form"
                                activeStepIndex={-1}
                            />
                        </Grid>
                        <Hidden smDown={true}>
                            <div id="images-container" className={styles['top-image-container']}>
                                <div className={styles['top-image-blue']}></div>
                                {/* <div className={styles['top-image-triangle1']}></div>
                                <div className={styles['top-image-triangle2']}></div> */}
                                <div id="mainview-image0" className={styles['top-image-image']} 
                                    style={{backgroundImage: `url(${mainImage})`}}>
                                </div>
                            </div>
                            <Grid item md={6} container direction="column" style={{padding: "0em 1.5em"}} className={styles['heading']}>
                                <Grid item>
                                    <div className={styles['top-text']}>
                                        <Typography variant="h2" color="inherit">
                                            Lisoń Transport Osobowy
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6" color="inherit">
                                        Busy do niemiec i holandii
                                    </Typography>
                                </Grid>
                                <Grid item >
                                    <div className={styles["subtitle-container"]}>
                                        <div>
                                            Polska
                                        </div>
                                        <div className={styles["subtitle-connector"]}>
                                        </div>
                                        <div>
                                            Niemcy
                                        </div>
                                        <div className={styles["subtitle-connector"]}>
                                        </div>
                                        <div>
                                            Holandia
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item style={{marginTop: "3em"}}>
                                    <div className={styles["arrow-container"]}>
                                        <div className={styles["arrow"]}></div>
                                        <Typography variant="h4" color="inherit">
                                            Sprawdź swoją trasę
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Grid>

                    <Grid 
                        container 
                        justifyContent="space-around" 
                        direction='row-reverse'>

                        <Grid item xs={12} md={6}>

                            <div className={styles["sections-container"]}>

                                <Grid container justifyContent="space-around">

                                    <Grid item xs={12} md={12}>
                                        <Routes
                                            id="routes"
                                            expanded={true}
                                            className={styles.section}
                                            expanded={this.props.route === "routes"}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <div className={styles["prices-component"]}>
                                            <Prices
                                                id="prices"
                                                expanded={true}
                                                className={styles.section}
                                                expanded={this.props.route === "prices"}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                            </div>

                            <div className={styles.section} style={{marginTop: "1em"}}>
                                <SectionContainer
                                    expandable={false}
                                    title="Masz Pytania?"
                                    icon={<HelpOutlineOutlinedIcon
                                        color="primary"
                                        style={{display: "block"}}
                                        fontSize="inherit"
                                    />}
                                    routeConfig={{name: "routes", to: "/maszpytania"}}>
                                </SectionContainer>
                            </div>
                            <div className={styles.section} style={{marginTop: "1em"}}>
                                <SectionContainer
                                    expandable={false}
                                    title="Kontakt"
                                    icon={<PersonPinOutlinedIcon
                                        color="primary"
                                        style={{display: "block"}}
                                        fontSize="inherit"
                                    />}
                                    routeConfig={{name: "routes", to: "/kontakt"}}>
                                </SectionContainer>
                            </div>
                            <div className={styles.section} style={{marginTop: "1em", marginBottom: "3em"}}>
                                <a href="https://www.facebook.com/Liso%C5%84-Transport-Osobowy-177801185630872/" target="blank"
                                    style={{textDecoration: "none", color: "inherit"}}>
                                    <SectionContainer
                                        expandable={false}
                                        title="Facebook"
                                        icon={<img src={FacebookLogo} style={{height: "35px", width: "35px", display: "block"}}/>}>
                                    </SectionContainer>
                                </a>
                            </div>

                        </Grid>

                        <Grid item xs={12} md={4}>
                            <NewsSection/>
                        </Grid>

                    </Grid>

                    <TeasersSection/>
                </Grid>
            </div>
        );
    
    }

}

export default MainView;