// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mKknDc9M2qnUViWDvJovSA\\=\\={width:4rem;height:31px}.L1N0bfgvVHqPHc1TDNa94Q\\=\\={flex-grow:0;display:flex;flex-direction:row;justify-content:center;align-items:center}@media only screen and (max-width: 960px){.L1N0bfgvVHqPHc1TDNa94Q\\=\\={flex-grow:1;display:flex;flex-direction:row;justify-content:center;align-items:center}}.SY1P57znUv7r-kQtCnxZVg\\=\\={display:flex;flex-direction:row;justify-content:space-between;width:100%}", "",{"version":3,"sources":["webpack://./src/js/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA,4BACI,UAAA,CACA,WAAA,CAGJ,4BACI,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAGJ,0CACI,4BACI,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAAA,CAIR,4BACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,UAAA","sourcesContent":[".logo {\n    width: 4rem;\n    height: 31px;\n}\n\n.logo-container {\n    flex-grow: 0;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n}\n\n@media only screen and (max-width: 960px) {\n    .logo-container {\n        flex-grow: 1;\n        display: flex;\n        flex-direction: row;\n        justify-content: center;\n        align-items: center;\n    }\n}\n\n.header-container{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "mKknDc9M2qnUViWDvJovSA==",
	"logo-container": "L1N0bfgvVHqPHc1TDNa94Q==",
	"header-container": "SY1P57znUv7r-kQtCnxZVg=="
};
export default ___CSS_LOADER_EXPORT___;
