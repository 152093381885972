import React from "react";

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { plPL } from '@material-ui/core/locale';

class CustomTheme extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
        };

        this.theme = createTheme({
            palette: {
                primary: {
                    main: "#033572"
                }
            }
        }, plPL);

    }

    render() {

        return (
            <ThemeProvider theme={this.theme}>
                {this.props.children}
            </ThemeProvider>
        );

    }

}

export default CustomTheme;