// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HLcYBRYWhwk4xSEVD1s8Ng\\=\\={text-decoration:none}.ebqWNAVe1r1EYhgol7BgLg\\=\\={margin-top:0em;width:100%}@media only screen and (max-width: 960px){.ebqWNAVe1r1EYhgol7BgLg\\=\\={margin-top:1em}}.YMvbZ5opy3bNhtWsmKfx-g\\=\\={display:flex;flex-direction:row;justify-content:center;padding-top:1.5em}", "",{"version":3,"sources":["webpack://./src/js/components/News/NewsSection/NewsSection.module.scss"],"names":[],"mappings":"AAEA,4BACI,oBAAA,CAGJ,4BACI,cAAA,CACA,UAAA,CAGJ,0CACI,4BACI,cAAA,CAAA,CAIR,4BACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA","sourcesContent":["\n\n.more-button {\n    text-decoration: none;\n}\n\n.main-container {\n    margin-top: 0em;\n    width: 100%;\n}\n\n@media only screen and (max-width: 960px) {\n    .main-container {\n        margin-top: 1em;\n    }\n}\n\n.button-container {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    padding-top: 1.5em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"more-button": "HLcYBRYWhwk4xSEVD1s8Ng==",
	"main-container": "ebqWNAVe1r1EYhgol7BgLg==",
	"button-container": "YMvbZ5opy3bNhtWsmKfx-g=="
};
export default ___CSS_LOADER_EXPORT___;
