import React from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Control from '../../Common/Control/Control.jsx';
import styles from './Tickets.module.scss';
import UserForm from "../User/User.jsx";
import ErrorBoundary from "../../Common/ErrorBoundary/ErrorBoundary.jsx";
import Formatter from "../../../utils/Formatter";
class Tickets extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const pricesSet = this.props.routeInfoData && this.props.routeInfoData.data ? this.props.routeInfoData.data.pricesSet : [];
        let oneDirectionPrices = [];
        let twoDirectionsPrices = [];
        for (let i = 0; i < pricesSet.length; ++i) {
            switch (pricesSet[i].type) {
                case "ONE_D" /* OneDirection */:
                    oneDirectionPrices = pricesSet[i].prices;
                    break;
                case "TWO_D" /* TwoDirections */:
                    twoDirectionsPrices = pricesSet[i].prices;
                    break;
            }
        }
        return (React.createElement(Control, { visible: this.props.stepFields[this.props.activeStep].ticketType },
            React.createElement(Grid, { container: true },
                React.createElement(Grid, { item: true, container: true, direction: "row", alignItems: "center", style: { marginTop: "1.5em", marginBottom: "1em" }, justifyContent: "flex-start" },
                    React.createElement(Grid, { item: true },
                        React.createElement(ButtonBase, { style: { marginRight: "3em" }, onClick: this.props.inputChange.bind(this.props.this, "ticketType", "ONE_D" /* OneDirection */) },
                            React.createElement(Paper, { className: styles["prices-container"] +
                                    (this.props.ticketType === "ONE_D" /* OneDirection */ ? " " + styles['active-ticket'] : "") },
                                React.createElement(Grid, { container: true, direction: "column" }, oneDirectionPrices.map((price) => {
                                    return (React.createElement(Grid, { item: true, key: price.currency },
                                        React.createElement(Typography, { style: { padding: "0.5em" } },
                                            Formatter.formatPrice(price.amount),
                                            " ",
                                            price.currency)));
                                })),
                                React.createElement(Typography, { variant: "body2", className: styles['price-info-text'] }, "W jedn\u0105 stron\u0119")))),
                    React.createElement(Grid, { item: true },
                        React.createElement(ButtonBase, { onClick: this.props.inputChange.bind(this.props.this, "ticketType", "TWO_D" /* TwoDirections */) },
                            React.createElement(Paper, { className: styles["prices-container"] +
                                    (this.props.ticketType === "TWO_D" /* TwoDirections */ ? " " + styles['active-ticket'] : "") },
                                React.createElement(Grid, { container: true, direction: "column" }, twoDirectionsPrices.map((price) => {
                                    return (React.createElement(Grid, { item: true, key: price.currency },
                                        React.createElement(Typography, { style: { padding: "0.5em" } },
                                            Formatter.formatPrice(price.amount),
                                            " ",
                                            price.currency,
                                            "*")));
                                })),
                                React.createElement(Typography, { variant: "body2", className: styles['price-info-text'] }, "W dwie strony")))),
                    React.createElement(Grid, { item: true, style: { color: "red" }, xs: 12 },
                        React.createElement(Control, { visible: this.props.fieldsState.ticketType.error },
                            React.createElement(Typography, { variant: "body2", color: "inherit", style: { paddingTop: "1em" } }, "Wybierz rodzaj biletu")))),
                React.createElement(Grid, { item: true, container: true },
                    React.createElement(Grid, { item: true, style: { marginRight: "0.5em" } },
                        React.createElement(TextField, { label: "Ilo\u015B\u0107 os\u00F3b doros\u0142ych", value: this.props.fieldsValues.noOfAdults, onChange: this.props.inputChange.bind(this.props.this, "noOfAdults"), fullWidth: true, margin: "normal", error: this.props.fieldsState.noOfAdults.error, helperText: this.props.fieldsState.noOfAdults.infoText })),
                    React.createElement(Grid, { item: true },
                        React.createElement(TextField, { label: "Ilo\u015B\u0107 dzieci", value: this.props.fieldsValues.noOfKids, onChange: this.props.inputChange.bind(this.props.this, "noOfKids"), fullWidth: true, margin: "normal", error: this.props.fieldsState.noOfKids.error, helperText: this.props.fieldsState.noOfKids.infoText }))),
                React.createElement(Control, { visible: this.props.noOfAdults > 0, className: styles['adults-container'] },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "h6" }, "Doro\u015Bli")),
                    React.createElement(Grid, { item: true, container: true, spacing: 2, direction: "column" }, Array.from({ length: this.props.noOfAdults }, (kid, index) => {
                        return (React.createElement(Grid, { item: true, style: { marginRight: "0.5em" }, key: index }, React.createElement(ErrorBoundary, null,
                            React.createElement(UserForm, { index: index, pathPrefix: "adult", firstName: this.props.fieldsValues[`adult/${index}/firstName`], firstNameStatus: this.props.fieldsState[`adult/${index}/firstName`], lastName: this.props.fieldsValues[`adult/${index}/lastName`], lastNameStatus: this.props.fieldsState[`adult/${index}/lastName`], this: this.props.this, inputChange: this.props.inputChange }))));
                    }))),
                React.createElement(Control, { visible: this.props.noOfKids > 0, className: styles['children-container'] },
                    React.createElement(Grid, { item: true },
                        React.createElement(Typography, { variant: "h6" }, "Dzieci")),
                    React.createElement(Grid, { item: true, container: true, spacing: 2, direction: "column" }, Array.from({ length: this.props.noOfKids }, (kid, index) => {
                        return (React.createElement(Grid, { item: true, style: { marginRight: "0.5em" }, key: index }, React.createElement(ErrorBoundary, null,
                            React.createElement(UserForm, { index: index, pathPrefix: "kid", firstName: this.props.fieldsValues[`kid/${index}/firstName`], firstNameStatus: this.props.fieldsState[`kid/${index}/firstName`], lastName: this.props.fieldsValues[`kid/${index}/lastName`], lastNameStatus: this.props.fieldsState[`kid/${index}/lastName`], dateOfBirth: this.props.fieldsValues[`kid/${index}/dateOfBirth`], dateOfBirthStatus: this.props.fieldsState[`kid/${index}/dateOfBirth`], this: this.props.this, inputChange: this.props.inputChange }))));
                    }))))));
    }
}
export default Tickets;
