import React from "react";

// import {  } from "@material-ui/core";

import styles from "./MenuPC.module.scss";
import { Link } from "react-router-dom";
import { List, ListItem, ListItemText, Button } from "@material-ui/core";


class MenuPC extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {

        return (
            <div className={styles["main-container"]}>
                <Link to="/" style={{textDecoration: "none"}} className={styles["button"]}>
                    <Button color="inherit">
                        Strona główna
                    </Button>
                </Link>
                <Link to="/?sekcja=trasy" style={{textDecoration: "none"}} className={styles["button"]}>
                    <Button color="inherit">
                        Trasy
                    </Button>
                </Link>
                <Link to="/?sekcja=cennik" style={{textDecoration: "none"}} className={styles["button"]}>
                    <Button color="inherit">
                        Cennik
                    </Button>
                </Link>
                <Link to="/maszpytania" style={{textDecoration: "none"}} className={styles["button"]}>
                    <Button color="inherit">
                        Masz Pytania?
                    </Button>
                </Link>
                <Link to="/kontakt" style={{textDecoration: "none"}} className={styles["button"]}>
                    <Button color="inherit">
                        Kontakt
                    </Button>
                </Link>
                <Link to="/aktualnosci" style={{textDecoration: "none"}} className={styles["button"]}>
                    <Button color="inherit">
                        Aktualności
                    </Button>
                </Link>
            </div>
        );
    }

}

export default MenuPC;