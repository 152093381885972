import React, { Component } from "react";

import styles from "./FooterSection.module.scss";
import { Typography } from "@material-ui/core";
import FooterSubsectionList from "../FooterSubsectionList/FooterSubsectionList.jsx";

class FooterSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {
        return (
            <div className={styles["main-container"]}>
                <Typography variant="body1">{this.props.title}</Typography>
                <FooterSubsectionList>
                    {this.props.children}
                </FooterSubsectionList>
            </div>
        );
    }

}

export default FooterSection;