import React from "react";
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Control from '../../Common/Control/Control.jsx';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, MenuItem, TextField } from "@material-ui/core";
export default class Payment extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const paymentTypes = [
            React.createElement(MenuItem, { key: 1, value: 'CONTROLLED' }, "Szybka p\u0142atno\u015B\u0107 online"),
            React.createElement(MenuItem, { key: 2, value: 'MANUAL' }, "Przelew tradycyjny"),
            React.createElement(MenuItem, { key: 4, value: 'PREPAID' }, "Mam op\u0142acony przejazd")
        ];
        if (this.props.fieldsValues.ticketType === "ONE_D" /* OneDirection */) {
            paymentTypes.push(React.createElement(MenuItem, { key: 3, value: 'DRIVER' }, "U kierowcy"));
        }
        return (React.createElement(Control, { visible: this.props.stepFields[this.props.activeStep].paymentType },
            React.createElement(Grid, { container: true },
                React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 6, lg: 6 },
                    React.createElement(FormControl, { error: this.props.fieldsState.paymentType.error, fullWidth: true },
                        React.createElement(InputLabel, { htmlFor: "payment-type" }, "Typ P\u0142atno\u015Bci"),
                        React.createElement(Select, { id: "payment-type", value: this.props.fieldsValues.paymentType, onChange: this.props.inputChange.bind(this.props.this, "paymentType") }, paymentTypes),
                        React.createElement(FormHelperText, null, this.props.fieldsState.paymentType.infoText))),
                React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, lg: 12, style: { marginTop: "1em" } },
                    React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: this.props.fieldsValues.invoice, name: "invoice", onChange: this.props.inputChange.bind(this.props.this, "invoice") }), label: "Faktura" })),
                React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, lg: 12 },
                    React.createElement(Control, { visible: this.props.fieldsValues.invoice === true },
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 12, lg: 12 },
                                React.createElement(TextField, { style: { paddingRight: "0.5em" }, label: "Nazwa Firmy", value: this.props.fieldsValues.payAddrCompanyName, onChange: this.props.inputChange.bind(this.props.this, "payAddrCompanyName"), fullWidth: true, margin: "normal", error: this.props.fieldsState.payAddrCompanyName.error, helperText: this.props.fieldsState.payAddrCompanyName.infoText, inputProps: {
                                        autoComplete: "section-payment billing organization"
                                    } }))),
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 6, lg: 6 },
                                React.createElement(TextField, { style: { paddingRight: "0.5em" }, label: "NIP", value: this.props.fieldsValues.payAddrVatId, onChange: this.props.inputChange.bind(this.props.this, "payAddrVatId"), fullWidth: true, margin: "normal", error: this.props.fieldsState.payAddrVatId.error, helperText: this.props.fieldsState.payAddrVatId.infoText, inputProps: {
                                        autoComplete: "section-payment billing off"
                                    } }))),
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 6, lg: 6 },
                                React.createElement(TextField, { style: { paddingRight: "0.5em" }, label: "Ulica", value: this.props.fieldsValues.payAddrStreet, onChange: this.props.inputChange.bind(this.props.this, "payAddrStreet"), fullWidth: true, margin: "normal", error: this.props.fieldsState.payAddrStreet.error, helperText: this.props.fieldsState.payAddrStreet.infoText, inputProps: {
                                        autoComplete: "section-payment billing address-line1"
                                    } })),
                            React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 6, lg: 6 },
                                React.createElement(TextField, { style: { paddingRight: "0.5em" }, label: "Numer Domu / Mieszkania", value: this.props.fieldsValues.payAddrNumber, onChange: this.props.inputChange.bind(this.props.this, "payAddrNumber"), fullWidth: true, margin: "normal", error: this.props.fieldsState.payAddrNumber.error, helperText: this.props.fieldsState.payAddrNumber.infoText, inputProps: {
                                        autoComplete: "section-payment billing address-line2"
                                    } }))),
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 6, lg: 6 },
                                React.createElement(TextField, { style: { paddingRight: "0.5em" }, label: "Kod Pocztowy", value: this.props.fieldsValues.payAddrPostalCode, onChange: this.props.inputChange.bind(this.props.this, "payAddrPostalCode"), fullWidth: true, margin: "normal", error: this.props.fieldsState.payAddrPostalCode.error, helperText: this.props.fieldsState.payAddrPostalCode.infoText, inputProps: {
                                        autoComplete: "section-payment billing postal-code"
                                    } })),
                            React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 6, lg: 6 },
                                React.createElement(TextField, { style: { paddingRight: "0.5em" }, label: "Miejscowo\u015B\u0107", value: this.props.fieldsValues.payAddrCity, onChange: this.props.inputChange.bind(this.props.this, "payAddrCity"), fullWidth: true, margin: "normal", error: this.props.fieldsState.payAddrCity.error, helperText: this.props.fieldsState.payAddrCity.infoText, inputProps: {
                                        autoComplete: "section-payment billing address-level2"
                                    } }))),
                        React.createElement(Grid, { container: true },
                            React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 6, lg: 6 },
                                React.createElement(FormControl, { error: this.props.fieldsState.payAddrCountry.error, margin: "normal", fullWidth: true, style: { paddingRight: "0.5em" } },
                                    React.createElement(InputLabel, { htmlFor: "country-select" }, "Kraj"),
                                    React.createElement(Select, { id: 'country-select', value: this.props.fieldsValues.payAddrCountry, onChange: this.props.inputChange.bind(this.props.this, "payAddrCountry"), fullWidth: true },
                                        React.createElement(MenuItem, { key: 1, value: 'PL' }, "Polska"),
                                        React.createElement(MenuItem, { key: 2, value: 'DE' }, "Niemcy"),
                                        React.createElement(MenuItem, { key: 3, value: 'NL' }, "Holandia"),
                                        React.createElement(MenuItem, { key: 4, value: 'BE' }, "Belgia")),
                                    React.createElement(FormHelperText, null, this.props.fieldsState.payAddrCountry.infoText)))))))));
    }
}
