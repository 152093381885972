import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import { throttle, isEqual } from 'lodash';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function GoogleMaps(props) {

  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  React.useEffect(() => {

    let currentValue = value ? value.description : null;
    let newValue = props.address ? props.address.description : null;

    if(!isEqual(currentValue, newValue)) {
        setValue(props.address);
    }

  });

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=' + GOOGLE_API_KEY + '&libraries=places',
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () => {
        return throttle((request, callback) => {
          autocompleteService.current.getPlacePredictions(request, callback);
        }, 500);
    },
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({
      input: inputValue,
      componentRestrictions: {
        country: [
          'be',
          'pl',
          'nl',
          'de'
        ]
      }
    }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
        id={props.id}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions={true}
        freeSolo
        value={value}
        onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            props.setParentValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            props.setParentValue({
                description: newInputValue,
                structured_formatting: {
                    main_text: newInputValue,
                    main_text_matched_substrings: [{length: newInputValue.length, offset: 0}]
                }
            });

        }}
        renderInput={(params) => {

            params.inputProps.autoComplete = "nieznany_atrybut_sialala";

            return (
                <TextField
                    {...params} 
                    placeholder="Wpisz pełen adres"
                    variant="standard"
                    fullWidth
                    error={props.error}
                    helperText={props.helperText}
                    onFocus={props.onFocus}
                />
            )
        }}
        renderOption={(option) => {
            const matches = option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
            );

            return (
            <Grid container alignItems="center">
                <Grid item>
                <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                    </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                    {option.structured_formatting.secondary_text}
                </Typography>
                </Grid>
            </Grid>
            );
        }}
    />
  );
}