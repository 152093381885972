// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eitIIJilLRWUOuljAr6CZQ\\=\\={padding:1em;border-width:2px;border-style:solid;border-color:transparent}.ku2exnwYP7Wl-0PPiBoguQ\\=\\={color:#616161}.thUm\\+sp\\+lxvjw1LaG6aRJg\\=\\={border-color:green;border-width:2px;border-style:solid}.lu93LR5PVV-hqgEttcU1Qg\\=\\={padding-top:2.5em}.jiswl\\+cO36AO-JRiZpltjw\\=\\={padding-top:2.5em}", "",{"version":3,"sources":["webpack://./src/js/components/RouteForm/Tickets/Tickets.module.scss","webpack://./src/css/_variables.module.scss"],"names":[],"mappings":"AAEA,4BACI,WAAA,CACA,gBAAA,CACA,kBAAA,CACA,wBAAA,CAGJ,4BACI,aCTgB,CDYpB,8BACI,kBCIY,CDHZ,gBAAA,CACA,kBAAA,CAGJ,4BACI,iBAAA,CAGJ,6BACI,iBAAA","sourcesContent":["@import \"../../../../css/variables.module\";\n\n.prices-container {\n    padding: 1em;\n    border-width: 2px;\n    border-style: solid;\n    border-color: transparent;\n}\n\n.price-info-text {\n    color: $subtle-text-color;\n}\n\n.active-ticket {\n    border-color: $success_color;\n    border-width: 2px;\n    border-style: solid;\n}\n\n.children-container {\n    padding-top: 2.5em;\n}\n\n.adults-container {\n    padding-top: 2.5em;\n}","$primary-color: #033572;\n$subtle-text-color: #616161;\n\n$first-section-margin: 1.5em;\n$basic-border-radius: 20px;\n$basic-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);\n\n//footer\n$footer-bg-color: #c5c5c5;\n$footer-section-title-color: #575757;\n$footer-subsection-color: white;\n\n$rental-bg-color: #E9F9FF;\n\n$color-5: #EF8354;\n\n$basic-font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n\n$success_color: green;\n$error_color: red;\n$link-color: #0AC2FF;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"prices-container": "eitIIJilLRWUOuljAr6CZQ==",
	"price-info-text": "ku2exnwYP7Wl-0PPiBoguQ==",
	"active-ticket": "thUm+sp+lxvjw1LaG6aRJg==",
	"children-container": "lu93LR5PVV-hqgEttcU1Qg==",
	"adults-container": "jiswl+cO36AO-JRiZpltjw=="
};
export default ___CSS_LOADER_EXPORT___;
