import React from "react";
import SectionContainer from "../SectionContainer/SectionContainer.jsx";
import styles from "./Prices.module.scss";
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import PriceTable from './PriceTable/PriceTable';
import Request from '../../utils/Request';
class Prices extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            basicPrices: undefined
        };
        this.scrollToForm = this.scrollToForm.bind(this);
        this.loadPricesDefinition = this.loadPricesDefinition.bind(this);
        this.renderPricesGroups = this.renderPricesGroups.bind(this);
        this.loadPricesDefinition();
    }
    loadPricesDefinition() {
        new Request().get(`${BACKEND_SERVICE}/BasicPrices`).then((result) => {
            const parsedResult = JSON.parse(result).data;
            this.setState({
                basicPrices: parsedResult
            });
        });
    }
    scrollToForm(withoutAnimation) {
        document.documentElement.scrollIntoView({
            behavior: withoutAnimation ? "auto" : "smooth"
        });
        setTimeout(function () {
            document.getElementById("input-address-from").focus();
        }, 0);
    }
    renderPricesGroups() {
        if (this.state.basicPrices === undefined) {
            return null;
        }
        const groups = [];
        let i = 0;
        for (const name in this.state.basicPrices) {
            groups.push(React.createElement(Accordion, { key: name, style: { marginTop: i === 0 ? "1em" : "0" } },
                React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMoreOutlinedIcon, null) },
                    React.createElement(Typography, null, name)),
                React.createElement(AccordionDetails, null,
                    React.createElement("div", { className: styles["price-group-container"] },
                        React.createElement(PriceTable, { basicPrices: this.state.basicPrices[name] }),
                        React.createElement("div", { className: styles["price-group-additional-info"] },
                            React.createElement(Typography, { variant: "body2" }, "*cena dotyczy biletu dwukierunkowego otwartego p\u0142atnego w formie przedp\u0142aty (przelew tradycyjny lub p\u0142atno\u015Bci online)"))))));
            ++i;
        }
        return groups;
    }
    render() {
        let routeConfig;
        if (this.props.expanded) {
            routeConfig = { name: "main", to: "/" };
        }
        else {
            routeConfig = { name: "prices", to: "/?sekcja=cennik" };
        }
        return (React.createElement("div", { id: this.props.id, className: this.props.className + " " + styles["main-container"] },
            React.createElement(SectionContainer, { title: "Cennik", icon: React.createElement(AttachMoneyOutlinedIcon, { color: "primary", style: { display: "block" }, fontSize: "inherit" }), expandable: true, expanded: this.props.expanded, routeConfig: routeConfig },
                React.createElement("div", null,
                    React.createElement("div", { id: "map", style: { width: "100%" } },
                        React.createElement("iframe", { src: "https://www.google.com/maps/d/embed?mid=1KHozc8R_OuLOTmQSq75mv8hJbQZ4Dfo&ehbc=2E312F", width: "100%", height: "300", frameBorder: "0" })),
                    React.createElement("div", { style: { padding: "1em", display: "flex", flexDirection: "row" } },
                        React.createElement("p", { style: { color: "#BC5151", paddingRight: "1em" } }, "Strefa 1"),
                        React.createElement("p", { style: { color: "#5D67D3" } }, "Strefa 2")),
                    this.renderPricesGroups(),
                    React.createElement("div", { className: styles["button-container"] },
                        React.createElement(Button, { color: "primary", variant: "outlined", onClick: this.scrollToForm }, "SPRAWD\u0179 MOJ\u0104 CEN\u0118"))))));
    }
}
export default Prices;
