// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0VEyjTdOzyg\\+DiOlC1d0iQ\\=\\={width:100%}._3NrnUJuyusPBgi86h5hUrw\\=\\={display:flex;justify-content:center;margin-top:1.5em}", "",{"version":3,"sources":["webpack://./src/js/components/Routes/Routes.module.scss"],"names":[],"mappings":"AAAA,8BACI,UAAA,CAGJ,6BACI,YAAA,CACA,sBAAA,CACA,gBAAA","sourcesContent":[".main-container {\n    width: 100%;\n}\n\n.button-container {\n    display: flex;\n    justify-content: center;\n    margin-top: 1.5em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-container": "_0VEyjTdOzyg+DiOlC1d0iQ==",
	"button-container": "_3NrnUJuyusPBgi86h5hUrw=="
};
export default ___CSS_LOADER_EXPORT___;
