// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2IpGzJK00Q4hBnOtPPUDZA\\=\\={border-radius:20px;border-style:solid;border-width:1px;border-color:#033572;padding:1.5em;box-shadow:0px 2px 10px rgba(0,0,0,.25);height:100%}.xFkADYe9b2WKYQrZGXRkhA\\=\\={display:flex;flex-direction:column;align-items:center}.Maw9mMHxNGixxoUJY2a-hw\\=\\={color:#595959}", "",{"version":3,"sources":["webpack://./src/js/components/Teaser/Teaser.module.scss","webpack://./src/css/_variables.module.scss"],"names":[],"mappings":"AAEA,6BACI,kBCCkB,CAAA,kBAAA,CDClB,gBAAA,CACA,oBCNY,CDOZ,aAAA,CACA,uCCHW,CDIX,WAAA,CAGJ,4BACI,YAAA,CACA,qBAAA,CACA,kBAAA,CAGJ,4BACI,aAAA","sourcesContent":["@import \"../../../css/variables.module\";\n\n.main-container {\n    border-radius: $basic-border-radius;\n    border-style: solid;\n    border-width: 1px;\n    border-color: $primary-color;\n    padding: 1.5em;\n    box-shadow: $basic-shadow;\n    height: 100%;\n}\n\n.content-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.subtitle-container {\n    color: #595959;\n}","$primary-color: #033572;\n$subtle-text-color: #616161;\n\n$first-section-margin: 1.5em;\n$basic-border-radius: 20px;\n$basic-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);\n\n//footer\n$footer-bg-color: #c5c5c5;\n$footer-section-title-color: #575757;\n$footer-subsection-color: white;\n\n$rental-bg-color: #E9F9FF;\n\n$color-5: #EF8354;\n\n$basic-font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n\n$success_color: green;\n$error_color: red;\n$link-color: #0AC2FF;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-container": "_2IpGzJK00Q4hBnOtPPUDZA==",
	"content-container": "xFkADYe9b2WKYQrZGXRkhA==",
	"subtitle-container": "Maw9mMHxNGixxoUJY2a-hw=="
};
export default ___CSS_LOADER_EXPORT___;
