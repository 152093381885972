import React, { Component } from "react";

import styles from "./FooterSubsectionList.module.scss";

class FooterSubsectionList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {
        return (
            <div className={styles["main-container"]}>
                {this.props.children}
            </div>
        );
    }

}

export default FooterSubsectionList;