// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oSGKAsZTkbokB\\+Hxf4kftw\\=\\={width:100%;background-color:#c5c5c5;padding-bottom:1em}", "",{"version":3,"sources":["webpack://./src/js/components/Footer/Footer.module.scss","webpack://./src/css/_variables.module.scss"],"names":[],"mappings":"AAEA,6BACI,UAAA,CACA,wBCIc,CDHd,kBAAA","sourcesContent":["@import \"../../../css/variables.module\";\n\n.main-container {\n    width: 100%;\n    background-color: $footer-bg-color;\n    padding-bottom: 1em;\n}","$primary-color: #033572;\n$subtle-text-color: #616161;\n\n$first-section-margin: 1.5em;\n$basic-border-radius: 20px;\n$basic-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);\n\n//footer\n$footer-bg-color: #c5c5c5;\n$footer-section-title-color: #575757;\n$footer-subsection-color: white;\n\n$rental-bg-color: #E9F9FF;\n\n$color-5: #EF8354;\n\n$basic-font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n\n$success_color: green;\n$error_color: red;\n$link-color: #0AC2FF;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-container": "oSGKAsZTkbokB+Hxf4kftw=="
};
export default ___CSS_LOADER_EXPORT___;
