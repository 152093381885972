import React, { Component } from "react";

import styles from "./SimpleHeader.module.scss";
import { Typography } from "@material-ui/core";

class SimpleHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div className={styles["main-container"]} style={this.props.style}>
                <div className={styles["inner-container"]}>
                    <Typography variant={this.props.titleVariant}>{this.props.children}</Typography>
                </div>
            </div>
        );
    }

}

export default SimpleHeader;